// FORM
.form {
  display: grid;
  width: 100%;
  max-width: 880px;
}

.form__wrapper {
  position: relative;
  display: flex;

  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 2px;
    height: 16px;
    background-color: var(--accent-color);
    transition: background-color 0.2s, box-shadow 0.2s;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  @media (min-width: 1024px) {
    padding: 21px 14px 20px 24px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 0 16px 17px;
  }

  @media (max-width: 767px) {
    padding: 0 8px 7px;
  }
}

.form__line {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--accent-color);
  bottom: 0;
  left: 0;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.form__input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #fff;
  line-height: 160%;

  &::placeholder {
    color: #fff;
    opacity: 0.5;
  }

  @media (min-width: 1024px) {
    font-size: 24px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    min-height: 48px;
    font-size: 16px;
  }

  @media (max-width: 767px) {
    min-height: 40px;
    font-size: 12px;
  }
}

.form__label {
  &--file {
    position: relative;
    align-self: center;
    padding: 0 0 0 32px;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    opacity: 0.5;
    cursor: pointer;
    transition: 0.2s opacity;

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 21px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    @media (max-width: 767px) {
      align-self: flex-start;
      margin: 0 0 8px;
      padding: 19px 0 16px 31px;
      font-size: 12px;
      line-height: 16px;

      svg {
        width: 16px;
        height: 16px;
        left: 8px;
      }
    }
  }
}

.form__button {
  flex-shrink: 0;
  justify-content: center;

  @media (min-width: 768px) {
    margin-left: 20px;
  }

  @media (max-width: 767px) {
    align-self: flex-start;
    min-width: 168px;
  }
}

.form__wrapper input:hover ~ .form__label,
.form__wrapper input:focus ~ .form__label {
  opacity: 1;
}

.form__wrapper:focus-within {
  &::before,
  &::after {
    box-shadow: 0px 0px 12px var(--accent-color);
  }

  .form__line {
    box-shadow: 0px 0px 12px var(--accent-color);
  }
}

.form__wrapper._error {
  &::before,
  &::after {
    background-color: var(--error-color);
  }

  .form__line {
    background-color: var(--error-color);
  }
}

.form__wrapper._error:focus-within {
  &::before,
  &::after {
    box-shadow: 0px 0px 12px var(--error-color);
  }

  .form__line {
    box-shadow: 0px 0px 12px var(--error-color);
  }
}
