// SOCIAL
.social {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  padding: 0;
  list-style: none;
}

.social__link {
  display: flex;
  transition: 0.2s;

  &:hover,
  &:focus {
    color: var(--accent-color);
  }
}
