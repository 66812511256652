// ITEM STEP
.item-step {
  position: relative;
  display: grid;
  align-content: start;

  &:not(:last-child) .item-step__icon::before {
    content: "";
    position: absolute;
    top: 50%;
    height: 1px;
    background-size: cover;
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }

  @media (min-width: 1024px) {
    &:not(:last-child) .item-step__icon::before {
      right: -240px;
      width: 240px;
      background-image: url("../img/icons/step-line.svg");
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    &:not(:last-child) .item-step__icon::before {
      right: -160px;
      width: 160px;
      background-image: url("../img/icons/step-line-medium.svg");
    }
  }

  @media (max-width: 767px) {
    justify-items: center;
    text-align: center;

    &:not(:last-child) .item-step__icon::before {
      right: -120px;
      width: 120px;
      background-image: url("../img/icons/step-line-small.svg");
    }
  }
}

.item-step__title {
  order: 2;
  margin: 0 0 8px;
  color: #fff;
  font-weight: 700;
  line-height: 160%;

  @media (min-width: 1024px) {
    font-size: 18px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.item-step__content {
  display: grid;
  grid-row-gap: 8px;
  order: 3;

  p {
    margin: 0;
    color: #fff;
    line-height: 160%;
  }

  @media (min-width: 1024px) {
    p {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    p {
      font-size: 12px;
    }
  }

  @media (max-width: 767px) {
    p {
      font-size: 10px;
    }
  }
}

.item-step__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 1;
  width: 80px;
  height: 80px;
  margin: 0 0 16px;
  border: 1px solid transparent;
  background-image: url("../img/icons/step-border.png");
  background-size: cover;
  background-repeat: no-repeat;

  .pattern {
    border-radius: 50%;
  }

  svg {
    color: var(--accent-color);
    transition: color 0.2s;
  }

  @media (max-width: 767px) {
    width: 72px;
    height: 72px;
  }
}
