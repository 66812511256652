// HEADER
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  box-sizing: border-box;
  width: 100%;
  max-width: 100vw;

  &::before {
    content: "";
    position: absolute;
    width: 2224px;
    left: 50%;
    z-index: -1;
    background: radial-gradient(50% 50% at 50% 50%, #121238 0%, rgba(19, 19, 47, 0) 100%);
    pointer-events: none;
    transform: translateX(-50%);
  }

  @media (min-width: 1024px) {
    padding: 40px 0 0;

    &::before {
      top: -280px;
      height: 520px;
    }
  }

  @media (min-width: 480px) and (max-width: 1023px) {
    padding: 24px 0;

    &::before {
      top: -250px;
      height: 500px;
    }
  }

  @media (max-width: 479px) {
    padding: 16px 0;

    &::before {
      top: -102px;
      height: 204px;
    }
  }
}

.header__container {
  display: grid;
  grid-column-gap: 20px;
  align-items: center;
  max-width: 1820px;

  @media (min-width: 1440px) {
    grid-template-columns: minmax(max-content, 1fr) minmax(max-content, 220px) minmax(max-content, 356px) max-content;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    grid-template-columns: 60px minmax(max-content, 1fr) minmax(max-content, 185px) minmax(max-content, 185px) max-content;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: 60px minmax(max-content, 1fr) minmax(max-content, 185px) max-content;
  }

  @media (max-width: 767px) {
    grid-template-columns: 48px max-content 48px;
    justify-content: space-between;
  }
}

.header__burger {

  @media (min-width: 1440px) {
    display: none;
  }

  @media (max-width: 1439px) {
    position: relative;
    z-index: 50;
    display: flex;
    padding: 0;
    border: none;
    background: none;

    .pattern {
      fill: #fff;
    }

    &--closed {
      span {
        position: absolute;
        height: 1px;
        background-color: #fff;
        transition: 0.2s width;
      }
    }

    &--opened {
      span {
        position: absolute;
        top: 50%;
        height: 1px;
        background-color: #fff;
      }

      span:nth-child(1) {
        transform: translateY(-50%) rotate(-45deg);
      }

      span:nth-child(2) {
        display: none;
      }

      span:nth-child(3) {
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    left: -30px;
  }

  @media (min-width: 768px) and (max-width: 1439px) {
    width: 64px;
    height: 48px;

    &--opened {
      span {
        right: 16px;
        width: 23px;
      }
    }

    &--closed {
      span {
        right: 16px;
      }

      span:nth-child(1) {
        top: 15px;
        width: 24px;
      }

      span:nth-child(2) {
        top: 23px;
        width: 16px;
      }

      span:nth-child(3) {
        bottom: 15px;
        width: 24px;
      }

      &:hover,
      &:focus {
        span:nth-child(2) {
          width: 24px;
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    left: -24px;
  }

  @media (max-width: 767px) {
    width: 48px;
    height: 32px;

    &--opened {
      span {
        right: 8px;
        width: 14px;
      }
    }

    &--closed {
      span {
        right: 8px;
      }

      span:nth-child(1) {
        top: 9px;
        width: 16px;
      }

      span:nth-child(2) {
        top: 15px;
        width: 8px;
      }

      span:nth-child(3) {
        bottom: 9px;
        width: 16px;
      }

      &:hover,
      &:focus {
        span:nth-child(2) {
          width: 16px;
        }
      }
    }
  }

  @media (min-width: 480px) and (max-width: 767px) {
    left: -24px;
  }

  @media (max-width: 479px) {
    left: -16px;
  }
}

.header__logo {
  z-index: 50;

  @media (min-width: 1024px) {
    justify-self: start;
  }

  @media (min-width: 480px) and (max-width: 1023px) {
    width: 120px;
  }

  @media (max-width: 479px) {
    width: 96px;
  }
}

.header__phone {
  z-index: 50;

  @media (min-width: 1024px) {
    justify-self: start;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
  }

  @media (max-width: 1023px) {
    display: none;
  }
}

.header__recall {
  z-index: 50;

  @media (min-width: 768px) {
    justify-self: start;
  }

  @media (max-width: 767px) {
    position: relative;
    width: 48px;
    height: 32px;
    background-color: transparent;
    background-image: url("../img/icons/phone.svg");
    background-position: 12px 7px;
    background-repeat: no-repeat;
    font-size: 0;

    &::before {
      display: none;
    }
  }

  @media (min-width: 480px) and (max-width: 767px) {
    right: -24px;
  }

  @media (max-width: 479px) {
    right: -16px;
  }

  .pattern {
    display: none;
    clip-path: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
}

.header__discuss {
  z-index: 50;

  @media (min-width: 768px) {
    justify-self: end;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

// NAV
.nav {
  position: relative;
  z-index: 40;
  display: grid;
  opacity: 0;
  pointer-events: none;

  @media (max-width: 1439px) {
    &--opened {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      max-height: 100%;
      background: rgba(35, 22, 71, 0.6);
      backdrop-filter: blur(40px);
      opacity: 1;
      pointer-events: visible;
    }
  }
}

.nav__background {
  @media (max-width: 767px) {
      position: absolute;
      width: 100%;
      height: 64px;
      top: 0;
      left: 0;
      transition: transform 0.2s;
      z-index: 40;
      transform: translateX(-100%);
      background: rgb(35, 22, 71);
      backdrop-filter: blur(40px);
  }
}

.nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  min-height: 100%;
  height: 100%;
  justify-self: start;
  padding: 184px 30px 120px 24px;
  background: rgba(35, 22, 71, 0.6);
  backdrop-filter: blur(40px);
  transition: transform 0.2s;
  transform: translateX(-100%);

  @media (max-height: 767px) {
    padding: 120px 40px 120px 24px;
  }

  @media (min-width: 768px) {
    min-width: 304px;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 88px 30px 35px 40px;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      height: 0;
    }
  }
}

.nav__list {
  position: relative;
  // align-content: start;
  display: grid;
  // min-height: 100%;
  grid-row-gap: 24px;
  max-width: 320px;
  margin: 0 auto;
  padding: 0 10px 0 0;
  list-style: none;

  &::before {
    content: "";
    order: 50;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 34.9%, rgba(255, 255, 255, 0) 100%);
  }

  @media (min-width: 768px) {
    overflow-y: auto;
    max-height: 100%;
  }

  @media (max-width: 767px) {
    &::before {
      bottom: -15px;
    }
  }
}

.nav__item {
  display: grid;
  grid-template-columns: 1fr 24px;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;

  &.color-green {
    color: var(--color-green);

    .nav__sub-item::before {
      border-color: var(--color-green);
    }

    a {

      @media (hover: hover) {
        &:hover {
          color: var(--color-green);
          filter: drop-shadow(0 0 8px var(--color-green));

          .nav__name {
            color: var(--color-green);
          }
        }
      }
    }
  }

  &.color-purple {
    color: var(--color-purple);

    .nav__sub-item::before {
      border-color: var(--color-purple);
    }

    a {

      @media (hover: hover) {
        &:hover {
          color: var(--color-purple);
          filter: drop-shadow(0 0 8px var(--color-purple));

          .nav__name {
            color: var(--color-purple);
          }
        }
      }
    }
  }

  &.color-yellow {
    color: var(--color-yellow);

    .nav__sub-item::before {
      border-color: var(--color-yellow);
    }

    a {

      @media (hover: hover) {
        &:hover {
          color: var(--color-yellow);
          filter: drop-shadow(0 0 8px var(--color-yellow));

          .nav__name {
            color: var(--color-yellow);
          }
        }
      }
    }
  }

  &.color-pink {
    color: var(--color-pink);

    .nav__sub-item::before {
      border-color: var(--color-pink);
    }

    a {

      @media (hover: hover) {
        &:hover {
          color: var(--color-pink);
          filter: drop-shadow(0 0 8px var(--color-pink));

          .nav__name {
            color: var(--color-pink);
          }
        }
      }
    }
  }

  a {

    @media (hover: hover) {
      transition: var(--transition-main);
      transition-property: filter, color;
      will-change: filter;
    }
  }
}

.nav__link {
  display: grid;
  align-items: center;

  @media (min-width: 480px) {
    grid-template-columns: 40px max-content;
    grid-column-gap: 40px;
  }

  @media (max-width: 479px) {
    grid-template-columns: 24px max-content;
    grid-column-gap: 24px;
  }
}

.nav__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 50%;

  @media (min-width: 480px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 479px) {
    width: 24px;
    height: 24px;
  }
}

.nav__name {
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;

  @media (min-width: 480px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 479px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.nav__sub-open {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  padding: 0;
  height: 24px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: transparent;

  &::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 1px;
    background-color: #fff;
  }

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 8px;
    background-color: #fff;
  }

  &--opened {
    &::after {
      display: none;
    }
  }
}

.nav__sub-list {
  display: none;

  &--opened {
    display: grid;
    grid-row: 2/3;
    grid-column: 1/-1;
    grid-row-gap: 8px;
    list-style: none;
  }

  @media (min-width: 480px) {
    &--opened {
      padding: 8px 0 0;
    }
  }

  @media (max-width: 479px) {
    &--opened {
      padding: 16px 0 0;
    }
  }
}

.nav__sub-item {
  position: relative;
  display: grid;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    width: 8px;
    height: 8px;
    border: 1px solid var(--color-green);
    border-radius: 50%;
    transform: translateY(-50%);
  }

  @media (min-width: 480px) {
    padding: 0 0 0 80px;

    &::before {
      left: 16px;
    }
  }

  @media (max-width: 479px) {
    padding: 0 0 0 48px;

    &::before {
      left: 8px;
    }
  }
}

.nav__sub-link {
  color: #fff;
  font-weight: 500;

  @media (min-width: 480px) {
    padding: 10px 0;
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 479px) {
    font-size: 12px;
    padding: 4px 0;
    line-height: 16px;
  }
}

.nav__footer {

  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 767px) {
    position: relative;
    display: grid;
    justify-items: center;
    max-width: 320px;
    margin: 0 auto;

    &::before {
      content: "";
      position: absolute;
      bottom: 72px;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 34.9%, rgba(255, 255, 255, 0) 100%);
    }
  }

  @media (max-width: 479px) {
    &::before {
      bottom: 64px;}
  }
}

.nav__discuss {
  max-width: 192px;
  width: 100%;
  margin: 40px 0 24px;

  @media (max-width: 479px) {
    margin: 56px 0 26px -10px;
  }
}

.nav__download {
  margin: 0 0 40px;

  @media (max-width: 479px) {
    margin: 0 0 40px -10px;

    &::before {
      bottom: -3px;
    }
  }
}

.nav__socials {
  justify-content: space-between;
  width: 100%;
  max-width: 96px;
  padding: 40px 0 0;

  @media (max-width: 479px) {
    padding: 43px 0 0;
    margin: 0 0 0 -10px;
  }
}
