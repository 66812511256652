// PAGE 404
main {
  display: grid;
}

.page404 {
  display: grid;
  align-items: center;
  padding: 200px 0;

  @media (max-width: 767px) {
    padding: 100px 0;
  }
}

.page404__container {
  display: grid;
}

.page404__wrapper {
  display: grid;
  max-width: 960px;

  @media (max-width: 1439px) {
    max-width: 100%;
  }
}

.page404__title {
  justify-self: center;
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  margin: 0 0 40px;
  color: #fff;

  span {
    font-size: 200px;
    line-height: 200px;
    color: var(--accent-color);
  }

  @media (max-width: 479px) {
    font-size: 24px;

    span {
      font-size: 100px;
      line-height: 100px;
    }
  }
}

.page404__return {
  justify-self: center;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
}
