// BUTTON
.button {
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  transition: var(--transition-main);
  transition-property: color;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 767px) {
    font-size: 10px;
    line-height: 12px;
  }

  @media (hover: hover) {
    &:hover,
    &:focus {
      color: var(--accent-color);

      .button__bg {
        &::before {
          opacity: 0.25;
          visibility: visible;
        }
      }

      .pattern {
        opacity: 0.2;
        fill: var(--accent-color);

        &--blur {
          opacity: 1;
          visibility: visible;
        }
      }

      span {
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  > * {
    pointer-events: none;
  }

  span {
    position: relative;

    &::before {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: -1px;//-1 - fix for safari
      opacity: 0;
      visibility: hidden;
      filter: blur(8px);
      transition: var(--transition-main);
      transition-property: visibility, opacity;
      will-change: filter;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200px;
      height: 48px;
      background-color: var(--accent-color);
      opacity: 0;
      visibility: hidden;
      filter: blur(36px);
      transition: var(--transition-main);
      transition-property: visibility, opacity;
      transform: translate(-50%, -50%);
      will-change: filter;
    }
  }

  .pattern {
    opacity: 0.15;
    fill: #fff;
    transition: var(--transition-main);
    transition-property: fill, visibility, opacity;
    @include clipPathAngles(var(--btn-clip-w));

    &--blur {
      opacity: 0;
      visibility: hidden;
      filter: blur(24px);
      clip-path: none;
      will-change: filter;
    }
  }

  &--primary {
    position: relative;
    padding: 5px 0;
    background-color: transparent;

    @media (hover: hover) {
      &:hover,
      &:focus {
        &::before {
          opacity: 0;
        }

        span {
          &::after {
            opacity: 0.25;
            visibility: visible;
          }
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed var(--accent-color);
      opacity: 1;
      transition: var(--transition-main);
      transition-property: opacity;
    }

    span {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-color: var(--accent-color);
        opacity: 0;
        visibility: hidden;
        filter: blur(20px);
        transition: var(--transition-main);
        transition-property: visibility, opacity;
        transform: translate(-50%, -50%) scale(1.3);
        will-change: filter;
      }
    }
  }

  &--accent {
    position: relative;
    min-width: 184px;
    padding: 14px;
    border: none;
    background-color: transparent;

    @media (hover: hover) {
      &:hover,
      &:focus {
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: var(--btn-triangle-w);
      height: var(--btn-triangle-w);
      background-color: var(--accent-color);
      transition: var(--transition-main);
      transition-property: background-color, visibility, opacity;
      clip-path: polygon(0 0, 100% 0, 0 100%);
    }

    &::before {
      opacity: 0;
      visibility: hidden;
      filter: blur(8px);
      clip-path: none;
      will-change: filter;
    }
  }
}
