.border-clip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  stroke: var(--accent-color);
  stroke-width: 2px;

  svg {
    position: absolute;
  }

  &__tl {
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
  }

  &__br {
    right: 0;
    bottom: 0;
    width: 17px;
    height: 17px;
  }

  &__t {
    top: 0;
    left: 16px;
    width: calc(100% - 16px);
    height: 2px;
  }

  &__b {
    bottom: 0;
    left: 0;
    width: calc(100% - 16px);
    height: 2px;
  }

  &__l {
    top: 16px;
    left: 0;
    width: 2px;
    height: calc(100% - 16px);
  }

  &__r {
    top: 0;
    right: 0;
    width: 2px;
    height: calc(100% - 16px);
  }
}
