// TITLE
.title {
  position: relative;
  display: grid;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 34.9%, rgba(255, 255, 255, 0) 100%);
  }

  @media (min-width: 1024px) {
    padding: 24px 80px 40px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 8px 40px 40px;
  }

  @media (max-width: 767px) {
    padding: 8px 24px 24px;
  }
}

.title__text {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;

  @media (min-width: 1024px) {
    font-size: 48px;
    line-height: 62px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 479px) {
    span {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.title__decor {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--accent-color);

  @media (min-width: 768px) and (max-width: 1023px) {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 767px) {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.title__tag {
  display: flex;
  align-items: center;
  justify-self: start;
  border: 1px solid var(--accent-color);
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 480px) {
    margin: 24px 0 0;
    padding: 7px 20px;
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 479px) {
    margin: 8px 0 0;
    padding: 4px 9px;
    font-size: 10px;
    line-height: 14px;
  }
}
