@mixin fontRuble($f-name, $f-style, $f-weight, $f-url) {
  @font-face {
    font-weight: $f-weight;
    font-style: $f-style;
    font-family: $f-name;
    src: url('../fonts/#{$f-url}.ttf') format('truetype');
  }
}

@mixin font($f-name, $f-style, $f-weight, $f-url) {
  @font-face {
    font-weight: $f-weight;
    font-style: $f-style;
    font-family: $f-name;
    font-display: swap;
    src:
      url('../fonts/#{$f-url}.woff2') format('woff2'),
      url('../fonts/#{$f-url}.woff') format('woff');
  }
}

@include font('IBM Sans', normal, 300, 'ibm/ibm-300');
@include font('IBM Sans', normal, 400, 'ibm/ibm-400');
@include font('IBM Sans', italic, 400, 'ibm/ibm-400i');
@include font('IBM Sans', normal, 500, 'ibm/ibm-500');
@include font('IBM Sans', italic, 500, 'ibm/ibm-500i');
@include font('IBM Sans', normal, 700, 'ibm/ibm-700');
