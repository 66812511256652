// FOOTER
.footer {
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    width: 2224px;
    left: 50%;
    z-index: -1;
    background: radial-gradient(50% 50% at 50% 50%, #121238 0%, rgba(19, 19, 47, 0) 100%);
    pointer-events: none;
    transform: translateX(-50%);
  }

  @media (min-width: 1024px) {
    padding: 40px 0;

    &::before {
      bottom: -280px;
      height: 520px;
    }
  }

  @media (max-width: 1023px) {
    padding: 24px 0;

    &::before {
      bottom: -400px;
      height: 500px;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.footer__container {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 20px;
  align-items: center;
  justify-content: space-between;
  max-width: 1820px;
}

.footer__socials {
  pointer-events: visible;
}

.footer__download {
  pointer-events: visible;
}
