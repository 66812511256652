// ABOUT
.about {
  @media (min-width: 1024px) {
    padding: 80px 0;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }
}

.about__container {
  display: grid;
}

.about__wrapper {
  display: grid;
  width: 100%;

  @media (max-width: 1439px) {
    justify-self: center;
  }

  @media (min-width: 1024px) {
    max-width: 960px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.about__list {
  display: grid;
  padding: 0;
  list-style: none;

  @media (min-width: 1024px) {
    grid-row-gap: 40px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-row-gap: 32px;
  }

  @media (max-width: 767px) {
    grid-row-gap: 24px;
  }
}
