// CARD PROJECT
.card-project {
  position: relative;
  display: flex;
  border: 1px solid;
  justify-content: center;
  border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.4) 49.92%, rgba(255, 255, 255, 0.12) 100%);
  border-image-slice: 1;
  transition: box-shadow 0.2s;

  &:hover,
  &:focus {
    border: 1px solid var(--accent-color);
    box-shadow: 0 0 16px var(--accent-color);
  }

  @media (min-width: 1024px) {
    min-height: 358px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    min-height: 308px;
  }

  @media (max-width: 767px) {
    min-height: 244px;
  }

  &__inner {
    position: relative;
    display: grid;
    grid-template-areas:
      "image image"
      "title title"
      "text text"
      "tag link";
    align-content: start;
    justify-content: center;

    @media (min-width: 1024px) {
      grid-template-rows: 110px auto 1fr auto;
      padding: 32px 40px 32px;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      grid-template-rows: 102px auto 1fr auto;
      padding: 26px;
    }

    @media (max-width: 767px) {
      grid-template-rows: 76px auto 1fr auto;
      padding: 14px 16px 16px;
    }
  }
}

.projects-index__slide .card-project__inner {

  @media (min-width: 1024px) {
    padding: 32px;
  }
}

.card-project__title {
  grid-area: title;
  margin: 0 0 8px;
  color: #fff;
  font-weight: 700;
  text-align: center;

  @media (min-width: 1024px) {
    padding: 16px 0 0;
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 1023px) {
    padding: 8px 0 0;
    font-size: 18px;
    line-height: 24px;
  }
}

.card-project__text {
  grid-area: text;
  margin: 0 0 24px;
  color: #fff;
  line-height: 160%;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 14px;
  }

  @media (max-width: 1023px) {
    font-size: 12px;
  }

  @media (max-width: 767px) {
    max-width: 320px;
  }

  @media (max-width: 479px) {
    margin: 0 0 16px;
  }
}

.card-project__image-wrap {
  position: relative;
  display: grid;
  grid-area: image;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;

  @media (min-width: 1024px) {
    max-width: 260px;
    max-height: 110px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 226px;
    max-height: 88px;
  }

  @media (max-width: 767px) {
    max-width: 166px;
    max-height: 64px;
  }
}

.card-project__image {
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
}

.card-project__tag {
  grid-area: tag;
  justify-self: start;
}

.card-project__link {
  grid-area: link;
  justify-self: end;
  font-size: 12px;
  line-height: 16px;

  @media (min-width: 768px) {
    padding: 8px 0;
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 767px) {
    padding: 6px 0;
    font-size: 10px;
    line-height: 12px;
  }
}
