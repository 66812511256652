.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.clearfix {
  &::after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    line-height: 0;
    visibility: hidden;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;

  &.sticky {
    overflow: unset;
  }

  .header {
    flex: 0 0 auto;
  }

  main {
    position: relative;
    flex: 1 0 auto;

    @media (min-width: 1024px) {
      padding: 0 0 80px;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      padding: 0 0 40px;
    }

    @media (max-width: 767px) {
      padding: 0 0 24px;
    }
  }

  .footer {
    flex: 0 0 auto;
  }
}

.table {
  display: table;
  width: 100%;
  border: 1px solid hsl(0, 0%, 70%);
  border-collapse: collapse;

  td,
  th {
    vertical-align: top;
    padding: 5px 10px;
    border-bottom: 1px solid hsl(0, 0%, 70%);

    &:not(:first-child) {
      border-left: 1px solid hsl(0, 0%, 70%);
    }
  }

  th {
    text-align: inherit;
  }
}

.is-default-hidden {
  display: none !important;
}

// DISABLED SCROLL
.disabled-scroll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

// @supports (-webkit-line-clamp: 6) {
// 	.your-class {
// 			display: -webkit-box;
// 			white-space: normal;
// 			text-overflow: ellipsis;
// 			-webkit-box-orient: vertical;//
// 			-webkit-line-clamp: 6;
// 			overflow: hidden;
// 	}
// }
