// TAG MIDDLE
.tag-middle {
  position: relative;
}

.tag-middle__decor {
  position: absolute;
  top: -6px;
  left: 0px;
  color: var(--accent-color);
  transition: color 0.2s;

  @media (min-width: 768px) and (max-width: 1023px) {
    top: -6px;
    left: 0px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 767px) {
    top: -8px;
    left: 0px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
