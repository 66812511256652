// DECOR
.decor {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  margin: 0 auto;
}

.decor__ball {
  position: absolute;
  font-size: 0;
  line-height: 1;

  &--hidden {
    display: none;

    @media (min-width: 576px) {
      display: block;
    }
  }
}

.home__decor {
  .decor__ball {
    &--1 {
      top: 34%;
      left: -13%;

      @media (max-width: 1024px) {
        top: 6%;
        left: 11%;
      }

      @media (max-width: 767px) {
        top: 7%;
        left: 9%;
      }
    }

    &--2 {
      top: 78%;
      left: 31%;

      @media (max-width: 1024px) {
        top: 69%;
        left: 5%;
      }

      @media (max-width: 767px) {
        top: 83%;
        left: 13%;
      }
    }

    &--3 {
      top: 11%;
      left: 25%;

      @media (max-width: 1024px) {
        top: 10%;
        left: 44%;
      }

      @media (max-width: 767px) {
        top: 12%;
        left: 50%;
      }
    }

    &--4 {
      top: 8%;
      left: 62%;

      @media (max-width: 1024px) {
        top: 11%;
        left: 69%;
      }

      @media (max-width: 767px) {
        top: 19%;
        left: 73%;
      }
    }

    &--5 {
      top: 58%;
      left: 50%;

      @media (max-width: 1024px) {
        top: 55%;
        left: 45%;
      }

      @media (max-width: 767px) {
        top: 74%;
        left: 58%;
      }
    }

    &--6 {
      top: 82%;
      left: 75%;

      @media (max-width: 1024px) {
        top: 73%;
        left: 72%;
      }

      @media (max-width: 767px) {
        top: 87%;
        left: 75%;
      }
    }
  }
}

.projects__decor {
  .decor__ball {
    &--1 {
      top: 26%;
      left: -14%;

      @media (max-width: 1024px) {
        top: 7%;
        left: 11%;
      }

      @media (max-width: 767px) {
        top: 7%;
        left: 39%;
      }
    }

    &--2 {
      top: 6%;
      left: 29%;

      @media (max-width: 1024px) {
        top: 18%;
        left: 54%;
      }

      @media (max-width: 767px) {
        top: 9%;
        left: 12%;
      }
    }

    &--3 {
      top: 15%;
      left: 46%;

      @media (max-width: 1024px) {
        top: 11%;
        left: 75%;
      }

      @media (max-width: 767px) {
        top: 22%;
        left: 66%;
      }
    }
  }
}

.project__decor {
  .decor__ball {
    &--1 {
      top: 46%;
      left: -10%;

      @media (max-width: 1024px) {
        top: 8%;
        left: 24%;
      }

      @media (max-width: 767px) {
        top: 9%;
        left: 51%;
      }
    }

    &--2 {
      top: 7%;
      left: 36%;

      @media (max-width: 1024px) {
        top: 11%;
        left: 55%;
      }

      @media (max-width: 767px) {
        top: 14%;
        left: 16%;
      }
    }

    &--3 {
      top: 20%;
      left: 54%;

      @media (max-width: 1024px) {
        top: 12%;
        left: 70%;
      }

      @media (max-width: 767px) {
        top: 15%;
        left: 68%;
      }
    }
  }
}

.team__decor {
  .decor__ball {
    &--1 {
      top: 35%;
      left: -9%;

      @media (max-width: 1024px) {
        top: 13%;
        left: 20%;
      }

      @media (max-width: 767px) {
        top: 15%;
        left: 20%;
      }
    }

    &--2 {
      top: 10%;
      left: 34%;

      @media (max-width: 1024px) {
        top: 15%;
        left: 48%;
      }

      @media (max-width: 767px) {
        top: 11%;
        left: 52%;
      }
    }

    &--3 {
      top: 13%;
      left: 52%;

      @media (max-width: 1024px) {
        top: 9%;
        left: 72%;
      }

      @media (max-width: 767px) {
        top: 22%;
        left: 77%;
      }
    }
  }
}

.contacts__decor {
  .decor__ball {
    &--1 {
      top: 32%;
      left: -12%;

      @media (max-width: 1024px) {
        top: 5%;
        left: 4%;
      }

      @media (max-width: 767px) {
        top: 13%;
        left: 16%;
      }
    }

    &--2 {
      top: 10%;
      left: 28%;

      @media (max-width: 1024px) {
        top: 14%;
        left: 52%;
      }

      @media (max-width: 767px) {
        top: 12%;
        left: 59%;
      }
    }

    &--3 {
      top: 14%;
      left: 56%;

      @media (max-width: 1024px) {
        top: 16%;
        left: 65%;
      }

      @media (max-width: 767px) {
        top: 21%;
        left: 76%;
      }
    }

    &--4 {
      top: 80%;
      left: 17%;

      @media (max-width: 1024px) {
        top: 77%;
        left: 15%;
      }
    }

    &--5 {
      top: 70%;
      left: 38%;

      @media (max-width: 1024px) {
        top: 61%;
        left: 46%;
      }

      @media (max-width: 767px) {
        top: 74%;
        left: 58%;
      }
    }

    &--6 {
      top: 77%;
      left: 64%;

      @media (max-width: 1024px) {
        top: 75%;
        left: 75%;
      }
    }
  }
}
