@charset "UTF-8";
/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */
@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: "IBM Sans";
  font-display: swap;
  src: url("../fonts/ibm/ibm-300.woff2") format("woff2"), url("../fonts/ibm/ibm-300.woff") format("woff"); }

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "IBM Sans";
  font-display: swap;
  src: url("../fonts/ibm/ibm-400.woff2") format("woff2"), url("../fonts/ibm/ibm-400.woff") format("woff"); }

@font-face {
  font-weight: 400;
  font-style: italic;
  font-family: "IBM Sans";
  font-display: swap;
  src: url("../fonts/ibm/ibm-400i.woff2") format("woff2"), url("../fonts/ibm/ibm-400i.woff") format("woff"); }

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: "IBM Sans";
  font-display: swap;
  src: url("../fonts/ibm/ibm-500.woff2") format("woff2"), url("../fonts/ibm/ibm-500.woff") format("woff"); }

@font-face {
  font-weight: 500;
  font-style: italic;
  font-family: "IBM Sans";
  font-display: swap;
  src: url("../fonts/ibm/ibm-500i.woff2") format("woff2"), url("../fonts/ibm/ibm-500i.woff") format("woff"); }

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "IBM Sans";
  font-display: swap;
  src: url("../fonts/ibm/ibm-700.woff2") format("woff2"), url("../fonts/ibm/ibm-700.woff") format("woff"); }

:root {
  --color-green: #0fb56f;
  --color-purple: #835bf3;
  --color-yellow: #ffbf1c;
  --color-pink: #db488f;
  --accent-color: #0fb56f;
  --error-color: #eb5757;
  --btn-clip-w: 13px;
  --btn-triangle-w: 8px;
  --transition-main: .3s ease-out; }
  :root[data-theme='green'] {
    --accent-color: var(--color-green); }
  :root[data-theme='purple'] {
    --accent-color: var(--color-purple); }
  :root[data-theme='yellow'] {
    --accent-color: var(--color-yellow); }
  :root[data-theme='pink'] {
    --accent-color: var(--color-pink); }

/* smartphones, touchscreens */
/* stylus-based screens */
/* Nintendo Wii controller, Microsoft Kinect */
/* mouse, touch pad */
@-ms-viewport {
  width: device-width; }

[data-whatintent='touch'] *:focus,
[data-whatintent='mouse'] *:focus {
  outline: none; }

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; }

*::before, *::after {
  box-sizing: border-box; }

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%; }

* {
  scrollbar-color: var(--accent-color) #231647;
  scrollbar-width: thin; }
  @media (max-width: 767px) {
    * {
      scrollbar-width: none; } }

::-webkit-scrollbar {
  width: 8px;
  background-color: #231647; }
  @media (max-width: 767px) {
    ::-webkit-scrollbar {
      width: 0px; } }

::-webkit-scrollbar-thumb {
  height: 8px;
  background-color: var(--accent-color); }
  @media (max-width: 767px) {
    ::-webkit-scrollbar-thumb {
      height: 0px; } }

body {
  position: relative;
  font-family: "IBM sans", sans-serif;
  font-size: 18px;
  line-height: 160%;
  color: #fff;
  background-color: #231647;
  overflow-x: hidden;
  text-align: left;
  cursor: default;
  word-break: break-word;
  padding: 0;
  margin: 0;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[tabindex='-1']:focus {
  outline: none !important; }

[role='button'] {
  cursor: pointer; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0; }

a {
  text-decoration: none;
  color: inherit; }

button,
input {
  overflow: visible;
  text-transform: none; }

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none; }

button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer; }

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; }

input[type=submit] {
  width: auto; }

input[type='number'] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  resize: vertical; }

svg {
  fill: currentColor; }
  a svg,
  button svg {
    pointer-events: none; }
  svg:not(:root) {
    overflow: hidden; }

img {
  max-width: 100%;
  height: auto;
  border: none; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 2em;
  margin-bottom: 2em;
  border: 0;
  border-top: 1px solid #b3b3b3; }

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: 0; }

p {
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
  padding: 0;
  margin: 0; }

address {
  font-style: normal; }

ul, ol {
  margin: 0; }
  ul.no-style, ol.no-style {
    padding: 0;
    list-style: none; }

li {
  line-height: inherit; }

blockquote {
  padding: 0;
  margin: 0; }

code {
  padding: 0.06em 0.3em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 3px; }

kbd {
  padding: 0.06em 0.3em;
  color: #fff;
  background-color: #e6e6e6;
  border-radius: 3px; }
  kbd kbd {
    padding: 0; }

pre {
  display: block;
  width: 100%;
  margin-bottom: 0;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(0, 0, 0, 0.08); }
  pre code {
    background: transparent; }

[contenteditable=true]:empty:before {
  content: attr(data-placeholder);
  color: #131313;
  font-size: inherit;
  display: block;
  /* For Firefox */ }

select {
  appearance: none; }
  select::-ms-expand {
    display: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: currentColor;
  margin: 0;
  padding: 0; }

h1 {
  font-size: 42px;
  line-height: 51px;
  font-weight: 800; }

h2 {
  font-size: 42px;
  line-height: 51px;
  font-weight: 800; }

h3 {
  font-size: 28px;
  line-height: 34px; }

h4 {
  font-size: 22px; }

.container {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1024px) {
    .container {
      padding: 0 30px; } }
  @media (min-width: 480px) and (max-width: 1023px) {
    .container {
      padding: 0 24px; } }
  @media (max-width: 479px) {
    .container {
      padding: 0 16px; } }

input {
  font-family: "IBM sans", sans-serif; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important; }

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  line-height: 0;
  visibility: hidden; }

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh; }
  .wrapper.sticky {
    overflow: unset; }
  .wrapper .header {
    flex: 0 0 auto; }
  .wrapper main {
    position: relative;
    flex: 1 0 auto; }
    @media (min-width: 1024px) {
      .wrapper main {
        padding: 0 0 80px; } }
    @media (min-width: 768px) and (max-width: 1023px) {
      .wrapper main {
        padding: 0 0 40px; } }
    @media (max-width: 767px) {
      .wrapper main {
        padding: 0 0 24px; } }
  .wrapper .footer {
    flex: 0 0 auto; }

.table {
  display: table;
  width: 100%;
  border: 1px solid #b3b3b3;
  border-collapse: collapse; }
  .table td,
  .table th {
    vertical-align: top;
    padding: 5px 10px;
    border-bottom: 1px solid #b3b3b3; }
    .table td:not(:first-child),
    .table th:not(:first-child) {
      border-left: 1px solid #b3b3b3; }
  .table th {
    text-align: inherit; }

.is-default-hidden {
  display: none !important; }

.disabled-scroll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; }

.border-clip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  stroke: var(--accent-color);
  stroke-width: 2px; }
  .border-clip svg {
    position: absolute; }
  .border-clip__tl {
    top: 0;
    left: 0;
    width: 17px;
    height: 17px; }
  .border-clip__br {
    right: 0;
    bottom: 0;
    width: 17px;
    height: 17px; }
  .border-clip__t {
    top: 0;
    left: 16px;
    width: calc(100% - 16px);
    height: 2px; }
  .border-clip__b {
    bottom: 0;
    left: 0;
    width: calc(100% - 16px);
    height: 2px; }
  .border-clip__l {
    top: 16px;
    left: 0;
    width: 2px;
    height: calc(100% - 16px); }
  .border-clip__r {
    top: 0;
    right: 0;
    width: 2px;
    height: calc(100% - 16px); }

.button {
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  transition: var(--transition-main);
  transition-property: color; }
  @media (min-width: 768px) {
    .button {
      font-size: 16px;
      line-height: 20px; } }
  @media (max-width: 767px) {
    .button {
      font-size: 10px;
      line-height: 12px; } }
  @media (hover: hover) {
    .button:hover, .button:focus {
      color: var(--accent-color); }
      .button:hover .button__bg::before, .button:focus .button__bg::before {
        opacity: 0.25;
        visibility: visible; }
      .button:hover .pattern, .button:focus .pattern {
        opacity: 0.2;
        fill: var(--accent-color); }
        .button:hover .pattern--blur, .button:focus .pattern--blur {
          opacity: 1;
          visibility: visible; }
      .button:hover span::before, .button:focus span::before {
        opacity: 1;
        visibility: visible; } }
  .button > * {
    pointer-events: none; }
  .button span {
    position: relative; }
    .button span::before {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: -1px;
      opacity: 0;
      visibility: hidden;
      filter: blur(8px);
      transition: var(--transition-main);
      transition-property: visibility, opacity;
      will-change: filter; }
  .button__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .button__bg::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200px;
      height: 48px;
      background-color: var(--accent-color);
      opacity: 0;
      visibility: hidden;
      filter: blur(36px);
      transition: var(--transition-main);
      transition-property: visibility, opacity;
      transform: translate(-50%, -50%);
      will-change: filter; }
  .button .pattern {
    opacity: 0.15;
    fill: #fff;
    transition: var(--transition-main);
    transition-property: fill, visibility, opacity;
    clip-path: polygon(var(--btn-clip-w) 0, 100% 0, 100% calc(100% - var(--btn-clip-w)), calc(100% - var(--btn-clip-w)) 100%, 0 100%, 0 var(--btn-clip-w)); }
    .button .pattern--blur {
      opacity: 0;
      visibility: hidden;
      filter: blur(24px);
      clip-path: none;
      will-change: filter; }
  .button--primary {
    position: relative;
    padding: 5px 0;
    background-color: transparent; }
    @media (hover: hover) {
      .button--primary:hover::before, .button--primary:focus::before {
        opacity: 0; }
      .button--primary:hover span::after, .button--primary:focus span::after {
        opacity: 0.25;
        visibility: visible; } }
    .button--primary::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed var(--accent-color);
      opacity: 1;
      transition: var(--transition-main);
      transition-property: opacity; }
    .button--primary span::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: var(--accent-color);
      opacity: 0;
      visibility: hidden;
      filter: blur(20px);
      transition: var(--transition-main);
      transition-property: visibility, opacity;
      transform: translate(-50%, -50%) scale(1.3);
      will-change: filter; }
  .button--accent {
    position: relative;
    min-width: 184px;
    padding: 14px;
    border: none;
    background-color: transparent; }
    @media (hover: hover) {
      .button--accent:hover::before, .button--accent:focus::before {
        opacity: 1;
        visibility: visible; } }
    .button--accent::before, .button--accent::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: var(--btn-triangle-w);
      height: var(--btn-triangle-w);
      background-color: var(--accent-color);
      transition: var(--transition-main);
      transition-property: background-color, visibility, opacity;
      clip-path: polygon(0 0, 100% 0, 0 100%); }
    .button--accent::before {
      opacity: 0;
      visibility: hidden;
      filter: blur(8px);
      clip-path: none;
      will-change: filter; }

.card-project {
  position: relative;
  display: flex;
  border: 1px solid;
  justify-content: center;
  border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.4) 49.92%, rgba(255, 255, 255, 0.12) 100%);
  border-image-slice: 1;
  transition: box-shadow 0.2s; }
  .card-project:hover, .card-project:focus {
    border: 1px solid var(--accent-color);
    box-shadow: 0 0 16px var(--accent-color); }
  @media (min-width: 1024px) {
    .card-project {
      min-height: 358px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .card-project {
      min-height: 308px; } }
  @media (max-width: 767px) {
    .card-project {
      min-height: 244px; } }
  .card-project__inner {
    position: relative;
    display: grid;
    grid-template-areas: "image image" "title title" "text text" "tag link";
    align-content: start;
    justify-content: center; }
    @media (min-width: 1024px) {
      .card-project__inner {
        grid-template-rows: 110px auto 1fr auto;
        padding: 32px 40px 32px; } }
    @media (min-width: 768px) and (max-width: 1023px) {
      .card-project__inner {
        grid-template-rows: 102px auto 1fr auto;
        padding: 26px; } }
    @media (max-width: 767px) {
      .card-project__inner {
        grid-template-rows: 76px auto 1fr auto;
        padding: 14px 16px 16px; } }

@media (min-width: 1024px) {
  .projects-index__slide .card-project__inner {
    padding: 32px; } }

.card-project__title {
  grid-area: title;
  margin: 0 0 8px;
  color: #fff;
  font-weight: 700;
  text-align: center; }
  @media (min-width: 1024px) {
    .card-project__title {
      padding: 16px 0 0;
      font-size: 24px;
      line-height: 32px; } }
  @media (max-width: 1023px) {
    .card-project__title {
      padding: 8px 0 0;
      font-size: 18px;
      line-height: 24px; } }

.card-project__text {
  grid-area: text;
  margin: 0 0 24px;
  color: #fff;
  line-height: 160%;
  text-align: center; }
  @media (min-width: 1024px) {
    .card-project__text {
      font-size: 14px; } }
  @media (max-width: 1023px) {
    .card-project__text {
      font-size: 12px; } }
  @media (max-width: 767px) {
    .card-project__text {
      max-width: 320px; } }
  @media (max-width: 479px) {
    .card-project__text {
      margin: 0 0 16px; } }

.card-project__image-wrap {
  position: relative;
  display: grid;
  grid-area: image;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%; }
  @media (min-width: 1024px) {
    .card-project__image-wrap {
      max-width: 260px;
      max-height: 110px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .card-project__image-wrap {
      max-width: 226px;
      max-height: 88px; } }
  @media (max-width: 767px) {
    .card-project__image-wrap {
      max-width: 166px;
      max-height: 64px; } }

.card-project__image {
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%); }

.card-project__tag {
  grid-area: tag;
  justify-self: start; }

.card-project__link {
  grid-area: link;
  justify-self: end;
  font-size: 12px;
  line-height: 16px; }
  @media (min-width: 768px) {
    .card-project__link {
      padding: 8px 0;
      font-size: 12px;
      line-height: 16px; } }
  @media (max-width: 767px) {
    .card-project__link {
      padding: 6px 0;
      font-size: 10px;
      line-height: 12px; } }

.card-stack {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.08) 0%, #fff 49.92%, rgba(255, 255, 255, 0.08) 100%);
  border-image-slice: 1;
  transition: box-shadow 0.2s; }
  .card-stack:hover, .card-stack:focus {
    border: 1px solid var(--accent-color);
    box-shadow: 0 0 16px var(--accent-color); }
  @media (min-width: 1024px) {
    .card-stack {
      width: 160px;
      height: 160px; }
      .card-stack--margin {
        margin: 12px 10px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .card-stack {
      width: 148px;
      height: 148px; }
      .card-stack--margin {
        margin: 8px; } }
  @media (max-width: 767px) {
    .card-stack {
      width: 88px;
      height: 88px; }
      .card-stack--margin {
        margin: 6px; } }

@media (min-width: 768px) {
  .card-stack__image {
    transform: scale(1); } }

@media (min-width: 480px) and (max-width: 767px) {
  .card-stack__image {
    transform: scale(0.87); } }

@media (max-width: 479px) {
  .card-stack__image {
    transform: scale(0.55); } }

.decor {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  margin: 0 auto; }

.decor__ball {
  position: absolute;
  font-size: 0;
  line-height: 1; }
  .decor__ball--hidden {
    display: none; }
    @media (min-width: 576px) {
      .decor__ball--hidden {
        display: block; } }

.home__decor .decor__ball--1 {
  top: 34%;
  left: -13%; }
  @media (max-width: 1024px) {
    .home__decor .decor__ball--1 {
      top: 6%;
      left: 11%; } }
  @media (max-width: 767px) {
    .home__decor .decor__ball--1 {
      top: 7%;
      left: 9%; } }

.home__decor .decor__ball--2 {
  top: 78%;
  left: 31%; }
  @media (max-width: 1024px) {
    .home__decor .decor__ball--2 {
      top: 69%;
      left: 5%; } }
  @media (max-width: 767px) {
    .home__decor .decor__ball--2 {
      top: 83%;
      left: 13%; } }

.home__decor .decor__ball--3 {
  top: 11%;
  left: 25%; }
  @media (max-width: 1024px) {
    .home__decor .decor__ball--3 {
      top: 10%;
      left: 44%; } }
  @media (max-width: 767px) {
    .home__decor .decor__ball--3 {
      top: 12%;
      left: 50%; } }

.home__decor .decor__ball--4 {
  top: 8%;
  left: 62%; }
  @media (max-width: 1024px) {
    .home__decor .decor__ball--4 {
      top: 11%;
      left: 69%; } }
  @media (max-width: 767px) {
    .home__decor .decor__ball--4 {
      top: 19%;
      left: 73%; } }

.home__decor .decor__ball--5 {
  top: 58%;
  left: 50%; }
  @media (max-width: 1024px) {
    .home__decor .decor__ball--5 {
      top: 55%;
      left: 45%; } }
  @media (max-width: 767px) {
    .home__decor .decor__ball--5 {
      top: 74%;
      left: 58%; } }

.home__decor .decor__ball--6 {
  top: 82%;
  left: 75%; }
  @media (max-width: 1024px) {
    .home__decor .decor__ball--6 {
      top: 73%;
      left: 72%; } }
  @media (max-width: 767px) {
    .home__decor .decor__ball--6 {
      top: 87%;
      left: 75%; } }

.projects__decor .decor__ball--1 {
  top: 26%;
  left: -14%; }
  @media (max-width: 1024px) {
    .projects__decor .decor__ball--1 {
      top: 7%;
      left: 11%; } }
  @media (max-width: 767px) {
    .projects__decor .decor__ball--1 {
      top: 7%;
      left: 39%; } }

.projects__decor .decor__ball--2 {
  top: 6%;
  left: 29%; }
  @media (max-width: 1024px) {
    .projects__decor .decor__ball--2 {
      top: 18%;
      left: 54%; } }
  @media (max-width: 767px) {
    .projects__decor .decor__ball--2 {
      top: 9%;
      left: 12%; } }

.projects__decor .decor__ball--3 {
  top: 15%;
  left: 46%; }
  @media (max-width: 1024px) {
    .projects__decor .decor__ball--3 {
      top: 11%;
      left: 75%; } }
  @media (max-width: 767px) {
    .projects__decor .decor__ball--3 {
      top: 22%;
      left: 66%; } }

.project__decor .decor__ball--1 {
  top: 46%;
  left: -10%; }
  @media (max-width: 1024px) {
    .project__decor .decor__ball--1 {
      top: 8%;
      left: 24%; } }
  @media (max-width: 767px) {
    .project__decor .decor__ball--1 {
      top: 9%;
      left: 51%; } }

.project__decor .decor__ball--2 {
  top: 7%;
  left: 36%; }
  @media (max-width: 1024px) {
    .project__decor .decor__ball--2 {
      top: 11%;
      left: 55%; } }
  @media (max-width: 767px) {
    .project__decor .decor__ball--2 {
      top: 14%;
      left: 16%; } }

.project__decor .decor__ball--3 {
  top: 20%;
  left: 54%; }
  @media (max-width: 1024px) {
    .project__decor .decor__ball--3 {
      top: 12%;
      left: 70%; } }
  @media (max-width: 767px) {
    .project__decor .decor__ball--3 {
      top: 15%;
      left: 68%; } }

.team__decor .decor__ball--1 {
  top: 35%;
  left: -9%; }
  @media (max-width: 1024px) {
    .team__decor .decor__ball--1 {
      top: 13%;
      left: 20%; } }
  @media (max-width: 767px) {
    .team__decor .decor__ball--1 {
      top: 15%;
      left: 20%; } }

.team__decor .decor__ball--2 {
  top: 10%;
  left: 34%; }
  @media (max-width: 1024px) {
    .team__decor .decor__ball--2 {
      top: 15%;
      left: 48%; } }
  @media (max-width: 767px) {
    .team__decor .decor__ball--2 {
      top: 11%;
      left: 52%; } }

.team__decor .decor__ball--3 {
  top: 13%;
  left: 52%; }
  @media (max-width: 1024px) {
    .team__decor .decor__ball--3 {
      top: 9%;
      left: 72%; } }
  @media (max-width: 767px) {
    .team__decor .decor__ball--3 {
      top: 22%;
      left: 77%; } }

.contacts__decor .decor__ball--1 {
  top: 32%;
  left: -12%; }
  @media (max-width: 1024px) {
    .contacts__decor .decor__ball--1 {
      top: 5%;
      left: 4%; } }
  @media (max-width: 767px) {
    .contacts__decor .decor__ball--1 {
      top: 13%;
      left: 16%; } }

.contacts__decor .decor__ball--2 {
  top: 10%;
  left: 28%; }
  @media (max-width: 1024px) {
    .contacts__decor .decor__ball--2 {
      top: 14%;
      left: 52%; } }
  @media (max-width: 767px) {
    .contacts__decor .decor__ball--2 {
      top: 12%;
      left: 59%; } }

.contacts__decor .decor__ball--3 {
  top: 14%;
  left: 56%; }
  @media (max-width: 1024px) {
    .contacts__decor .decor__ball--3 {
      top: 16%;
      left: 65%; } }
  @media (max-width: 767px) {
    .contacts__decor .decor__ball--3 {
      top: 21%;
      left: 76%; } }

.contacts__decor .decor__ball--4 {
  top: 80%;
  left: 17%; }
  @media (max-width: 1024px) {
    .contacts__decor .decor__ball--4 {
      top: 77%;
      left: 15%; } }

.contacts__decor .decor__ball--5 {
  top: 70%;
  left: 38%; }
  @media (max-width: 1024px) {
    .contacts__decor .decor__ball--5 {
      top: 61%;
      left: 46%; } }
  @media (max-width: 767px) {
    .contacts__decor .decor__ball--5 {
      top: 74%;
      left: 58%; } }

.contacts__decor .decor__ball--6 {
  top: 77%;
  left: 64%; }
  @media (max-width: 1024px) {
    .contacts__decor .decor__ball--6 {
      top: 75%;
      left: 75%; } }

.form {
  display: grid;
  width: 100%;
  max-width: 880px; }

.form__wrapper {
  position: relative;
  display: flex; }
  .form__wrapper::before, .form__wrapper::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 2px;
    height: 16px;
    background-color: var(--accent-color);
    transition: background-color 0.2s, box-shadow 0.2s; }
  .form__wrapper::before {
    left: 0; }
  .form__wrapper::after {
    right: 0; }
  @media (min-width: 1024px) {
    .form__wrapper {
      padding: 21px 14px 20px 24px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .form__wrapper {
      padding: 0 16px 17px; } }
  @media (max-width: 767px) {
    .form__wrapper {
      padding: 0 8px 7px; } }

.form__line {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--accent-color);
  bottom: 0;
  left: 0;
  transition: background-color 0.2s, box-shadow 0.2s; }

.form__input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #fff;
  line-height: 160%; }
  .form__input::placeholder {
    color: #fff;
    opacity: 0.5; }
  @media (min-width: 1024px) {
    .form__input {
      font-size: 24px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .form__input {
      min-height: 48px;
      font-size: 16px; } }
  @media (max-width: 767px) {
    .form__input {
      min-height: 40px;
      font-size: 12px; } }

.form__label--file {
  position: relative;
  align-self: center;
  padding: 0 0 0 32px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.2s opacity; }
  .form__label--file svg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  @media (min-width: 768px) {
    .form__label--file {
      font-size: 16px;
      line-height: 21px; }
      .form__label--file svg {
        width: 24px;
        height: 24px; } }
  @media (max-width: 767px) {
    .form__label--file {
      align-self: flex-start;
      margin: 0 0 8px;
      padding: 19px 0 16px 31px;
      font-size: 12px;
      line-height: 16px; }
      .form__label--file svg {
        width: 16px;
        height: 16px;
        left: 8px; } }

.form__button {
  flex-shrink: 0;
  justify-content: center; }
  @media (min-width: 768px) {
    .form__button {
      margin-left: 20px; } }
  @media (max-width: 767px) {
    .form__button {
      align-self: flex-start;
      min-width: 168px; } }

.form__wrapper input:hover ~ .form__label,
.form__wrapper input:focus ~ .form__label {
  opacity: 1; }

.form__wrapper:focus-within::before, .form__wrapper:focus-within::after {
  box-shadow: 0px 0px 12px var(--accent-color); }

.form__wrapper:focus-within .form__line {
  box-shadow: 0px 0px 12px var(--accent-color); }

.form__wrapper._error::before, .form__wrapper._error::after {
  background-color: var(--error-color); }

.form__wrapper._error .form__line {
  background-color: var(--error-color); }

.form__wrapper._error:focus-within::before, .form__wrapper._error:focus-within::after {
  box-shadow: 0px 0px 12px var(--error-color); }

.form__wrapper._error:focus-within .form__line {
  box-shadow: 0px 0px 12px var(--error-color); }

.item-step {
  position: relative;
  display: grid;
  align-content: start; }
  .item-step:not(:last-child) .item-step__icon::before {
    content: "";
    position: absolute;
    top: 50%;
    height: 1px;
    background-size: cover;
    background-repeat: no-repeat;
    transform: translateY(-50%); }
  @media (min-width: 1024px) {
    .item-step:not(:last-child) .item-step__icon::before {
      right: -240px;
      width: 240px;
      background-image: url("../img/icons/step-line.svg"); } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .item-step:not(:last-child) .item-step__icon::before {
      right: -160px;
      width: 160px;
      background-image: url("../img/icons/step-line-medium.svg"); } }
  @media (max-width: 767px) {
    .item-step {
      justify-items: center;
      text-align: center; }
      .item-step:not(:last-child) .item-step__icon::before {
        right: -120px;
        width: 120px;
        background-image: url("../img/icons/step-line-small.svg"); } }

.item-step__title {
  order: 2;
  margin: 0 0 8px;
  color: #fff;
  font-weight: 700;
  line-height: 160%; }
  @media (min-width: 1024px) {
    .item-step__title {
      font-size: 18px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .item-step__title {
      font-size: 16px; } }
  @media (max-width: 767px) {
    .item-step__title {
      font-size: 12px; } }

.item-step__content {
  display: grid;
  grid-row-gap: 8px;
  order: 3; }
  .item-step__content p {
    margin: 0;
    color: #fff;
    line-height: 160%; }
  @media (min-width: 1024px) {
    .item-step__content p {
      font-size: 14px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .item-step__content p {
      font-size: 12px; } }
  @media (max-width: 767px) {
    .item-step__content p {
      font-size: 10px; } }

.item-step__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 1;
  width: 80px;
  height: 80px;
  margin: 0 0 16px;
  border: 1px solid transparent;
  background-image: url("../img/icons/step-border.png");
  background-size: cover;
  background-repeat: no-repeat; }
  .item-step__icon .pattern {
    border-radius: 50%; }
  .item-step__icon svg {
    color: var(--accent-color);
    transition: color 0.2s; }
  @media (max-width: 767px) {
    .item-step__icon {
      width: 72px;
      height: 72px; } }

@media (hover: hover) {
  .link {
    will-change: filter;
    transition: var(--transition-main);
    transition-property: filter, color, opacity; }
    .link:hover, .link:focus {
      color: var(--accent-color);
      filter: drop-shadow(0 0 12px var(--accent-color)); } }

.logo {
  display: flex; }

.logo__color {
  fill: var(--accent-color); }

@media (min-width: 1024px) {
  .offer {
    padding: 80px 0; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .offer {
    padding: 60px 0; } }

@media (max-width: 767px) {
  .offer {
    padding: 40px 0; } }

.offer__container {
  display: grid; }

.offer__wrapper {
  position: relative;
  display: grid;
  width: 100%; }
  .offer__wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 34.9%, rgba(255, 255, 255, 0) 100%); }
  @media (max-width: 1439px) {
    .offer__wrapper {
      justify-content: center;
      justify-self: center;
      width: 100%; } }
  @media (min-width: 1024px) {
    .offer__wrapper {
      max-width: 960px;
      padding: 80px 0 0; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .offer__wrapper {
      max-width: 640px;
      padding: 40px 0 0; } }
  @media (max-width: 767px) {
    .offer__wrapper {
      padding: 24px 0 0; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .offer__wrapper {
      max-width: 480px; } }
  @media (max-width: 479px) {
    .offer__wrapper {
      max-width: 360px; } }

.offer__sub-title {
  width: 100%;
  max-width: 880px;
  color: #fff;
  font-weight: 700; }
  @media (min-width: 1440px) {
    .offer__sub-title {
      justify-self: end; } }
  @media (min-width: 1024px) {
    .offer__sub-title {
      margin: 0 0 40px;
      font-size: 40px;
      line-height: 52px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .offer__sub-title {
      margin: 0 0 32px;
      font-size: 24px;
      line-height: 31px; } }
  @media (max-width: 767px) {
    .offer__sub-title {
      margin: 0 0 16px;
      font-size: 18px;
      line-height: 23px; } }

@media (min-width: 1024px) {
  .offer-project .offer__sub-title {
    margin: 0 0 10px; } }

@media (min-width: 1440px) {
  .offer__form {
    justify-self: end; } }

.pattern {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  fill: var(--accent-color); }
  .pattern--inside {
    top: 8px;
    left: 8px;
    width: calc(100% - 16px);
    height: calc(100% - 16px); }

.bg-pattern-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .bg-pattern-border .pattern {
    clip-path: polygon(12px 0, 100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%, 0 12px); }

.quote {
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto auto;
  height: 100%; }
  @media (min-width: 1024px) {
    .quote {
      padding: 24px 32px; } }
  @media (max-width: 1023px) {
    .quote {
      padding: 24px; } }

.quote__content {
  position: relative;
  display: grid;
  grid-row-gap: 8px;
  margin: 0 0 16px; }
  .quote__content p {
    margin: 0;
    color: #fff;
    line-height: 160%; }
  @media (min-width: 1024px) {
    .quote__content p {
      font-size: 18px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .quote__content p {
      font-size: 16px; } }
  @media (max-width: 767px) {
    .quote__content p {
      font-size: 12px; } }

.quote__author {
  position: relative;
  display: grid; }

.quote__name {
  margin: 0 0 8px;
  color: #fff;
  font-weight: 500;
  font-style: italic;
  line-height: 160%;
  text-align: right; }
  @media (min-width: 768px) {
    .quote__name {
      font-size: 18px; } }
  @media (max-width: 767px) {
    .quote__name {
      margin: 0;
      font-size: 12px; } }

.quote__position {
  color: #fff;
  font-style: italic;
  font-size: 12px;
  line-height: 160%;
  text-align: right; }
  @media (max-width: 767px) {
    .quote__position {
      font-size: 10px; } }

.slider {
  position: relative;
  display: grid; }

.slider__mask {
  position: relative;
  align-items: start;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  grid-row: 1/2;
  grid-column: 1/2;
  width: 100%;
  height: 100%;
  pointer-events: none; }
  @media (min-width: 1024px) {
    .slider__mask {
      mask-image: linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(196, 196, 196, 0.2) 5.8%, #c4c4c4 8.58%, #c4c4c4 91.44%, rgba(196, 196, 196, 0.2) 94.2%, rgba(196, 196, 196, 0) 100%); } }
  @media (min-width: 480px) and (max-width: 1023px) {
    .slider__mask {
      mask-image: linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(196, 196, 196, 0.2) 5.8%, #c4c4c4 8.58%, #c4c4c4 91.58%, rgba(196, 196, 196, 0.2) 96.94%, rgba(196, 196, 196, 0) 100%);
      width: calc(100% + 48px);
      margin: 0 -24px;
      padding: 0 24px; } }
  @media (max-width: 479px) {
    .slider__mask {
      width: 100vw;
      max-width: 480px;
      margin: 0 -16px;
      padding: 0 16px;
      mask-image: linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, #c4c4c4 5.24%, #c4c4c4 94.7%, rgba(196, 196, 196, 0) 100%); } }

@media (max-width: 479px) {
  .project__works .slider__mask {
    margin: 0;
    padding: 0; } }

.slider__container {
  display: grid;
  justify-self: center;
  pointer-events: visible; }

.slider__wrapper {
  display: flex;
  padding: 0;
  list-style: none; }

.slider__slide {
  flex-shrink: 0;
  margin: 0; }

@media (min-width: 768px) {
  .slider__buttons {
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(2, 40px);
    grid-row: 1/2;
    grid-column: 1/2;
    grid-column-gap: 20px;
    align-items: center;
    justify-content: space-between;
    pointer-events: none; } }

@media (max-width: 767px) {
  .slider__buttons {
    display: none; } }

.slider__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: #fff;
  pointer-events: visible;
  transition: background-color 0.2s, box-shadow 0.2s; }
  .slider__button svg {
    position: relative;
    color: #231647;
    transition: color 0.2s; }
  .slider__button--prev svg {
    left: -2px; }
  .slider__button--next svg {
    left: 2px; }
  .slider__button:hover, .slider__button:focus {
    background-color: var(--accent-color);
    box-shadow: 0 0 24px var(--accent-color); }
    .slider__button:hover svg, .slider__button:focus svg {
      color: #fff; }

.slider__dots {
  display: grid;
  grid-auto-flow: column;
  justify-content: center; }
  .slider__dots .swiper-pagination-bullet {
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer; }
  .slider__dots .swiper-pagination-bullet-active {
    border: none !important;
    background-color: var(--accent-color); }
  @media (min-width: 768px) {
    .slider__dots {
      grid-column-gap: 32px;
      padding: 40px 0 0; }
      .slider__dots .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        border: 2px solid #fff; } }
  @media (max-width: 767px) {
    .slider__dots {
      grid-column-gap: 16px;
      padding: 24px 0 0; }
      .slider__dots .swiper-pagination-bullet {
        width: 4px;
        height: 4px;
        border: 1px solid #fff; } }

.social {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  padding: 0;
  list-style: none; }

.social__link {
  display: flex;
  transition: 0.2s; }
  .social__link:hover, .social__link:focus {
    color: var(--accent-color); }

.sub-title {
  color: #fff;
  font-weight: 700; }
  @media (min-width: 1024px) {
    .sub-title {
      font-size: 40px;
      line-height: 52px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .sub-title {
      font-size: 32px;
      line-height: 42px; } }
  @media (max-width: 767px) {
    .sub-title {
      font-size: 18px;
      line-height: 24px; } }

.tag {
  border: 1px solid var(--accent-color);
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    .tag {
      padding: 7px 11px;
      font-size: 12px;
      line-height: 16px; } }
  @media (max-width: 767px) {
    .tag {
      padding: 5px;
      font-size: 10px;
      line-height: 12px; } }

.tag-middle {
  position: relative; }

.tag-middle__decor {
  position: absolute;
  top: -6px;
  left: 0px;
  color: var(--accent-color);
  transition: color 0.2s; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .tag-middle__decor {
      top: -6px;
      left: 0px; }
      .tag-middle__decor svg {
        width: 24px;
        height: 24px; } }
  @media (max-width: 767px) {
    .tag-middle__decor {
      top: -8px;
      left: 0px; }
      .tag-middle__decor svg {
        width: 16px;
        height: 16px; } }

.tag-small {
  position: relative;
  display: grid; }
  @media (min-width: 1024px) {
    .tag-small {
      padding-left: 80px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .tag-small {
      padding-left: 40px; } }
  @media (max-width: 767px) {
    .tag-small {
      padding-left: 24px; } }

.tag-small__title {
  margin: 0 0 8px;
  color: #fff;
  font-weight: 700; }
  @media (min-width: 1024px) {
    .tag-small__title {
      font-size: 40px;
      line-height: 52px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .tag-small__title {
      font-size: 24px;
      line-height: 32px; } }
  @media (max-width: 767px) {
    .tag-small__title {
      font-size: 18px;
      line-height: 24px; } }

.tag-small__content {
  display: grid;
  grid-row-gap: 8px; }
  .tag-small__content p {
    margin: 0;
    color: #fff;
    line-height: 160%; }
  @media (min-width: 1024px) {
    .tag-small__content p {
      font-size: 18px;
      line-height: 175%; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .tag-small__content p {
      font-size: 16px; } }
  @media (max-width: 767px) {
    .tag-small__content p {
      font-size: 12px; } }

.tag-small__decor {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  width: 24px;
  height: 24px;
  color: transparent;
  stroke: var(--accent-color); }
  @media (min-width: 768px) and (max-width: 1023px) {
    .tag-small__decor {
      width: 16px;
      height: 16px; }
      .tag-small__decor svg {
        width: 16px;
        height: 16px; } }
  @media (max-width: 767px) {
    .tag-small__decor {
      width: 8px;
      height: 8px; }
      .tag-small__decor svg {
        width: 8px;
        height: 8px; } }

.title {
  position: relative;
  display: grid; }
  .title::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 34.9%, rgba(255, 255, 255, 0) 100%); }
  @media (min-width: 1024px) {
    .title {
      padding: 24px 80px 40px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .title {
      padding: 8px 40px 40px; } }
  @media (max-width: 767px) {
    .title {
      padding: 8px 24px 24px; } }

.title__text {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase; }
  @media (min-width: 1024px) {
    .title__text {
      font-size: 48px;
      line-height: 62px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .title__text {
      font-size: 32px;
      line-height: 42px; } }
  @media (max-width: 767px) {
    .title__text {
      font-size: 24px;
      line-height: 32px; } }
  @media (max-width: 479px) {
    .title__text span {
      font-size: 18px;
      line-height: 24px; } }

.title__decor {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--accent-color); }
  @media (min-width: 768px) and (max-width: 1023px) {
    .title__decor svg {
      width: 24px;
      height: 24px; } }
  @media (max-width: 767px) {
    .title__decor svg {
      width: 16px;
      height: 16px; } }

.title__tag {
  display: flex;
  align-items: center;
  justify-self: start;
  border: 1px solid var(--accent-color);
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase; }
  @media (min-width: 480px) {
    .title__tag {
      margin: 24px 0 0;
      padding: 7px 20px;
      font-size: 12px;
      line-height: 16px; } }
  @media (max-width: 479px) {
    .title__tag {
      margin: 8px 0 0;
      padding: 4px 9px;
      font-size: 10px;
      line-height: 14px; } }

@media (min-width: 1024px) {
  .about {
    padding: 80px 0; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .about {
    padding: 60px 0; } }

@media (max-width: 767px) {
  .about {
    padding: 40px 0; } }

.about__container {
  display: grid; }

.about__wrapper {
  display: grid;
  width: 100%; }
  @media (max-width: 1439px) {
    .about__wrapper {
      justify-self: center; } }
  @media (min-width: 1024px) {
    .about__wrapper {
      max-width: 960px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .about__wrapper {
      max-width: 640px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .about__wrapper {
      max-width: 480px; } }
  @media (max-width: 479px) {
    .about__wrapper {
      max-width: 360px; } }

.about__list {
  display: grid;
  padding: 0;
  list-style: none; }
  @media (min-width: 1024px) {
    .about__list {
      grid-row-gap: 40px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .about__list {
      grid-row-gap: 32px; } }
  @media (max-width: 767px) {
    .about__list {
      grid-row-gap: 24px; } }

@media (min-width: 1024px) {
  .contacts {
    padding: 152px 0 80px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .contacts {
    padding: 245px 0 40px; } }

@media (max-width: 767px) {
  .contacts {
    padding: 124px 0 0; } }

.contacts__container {
  display: grid; }

.contacts__wrapper {
  display: grid;
  width: 100%; }
  @media (max-width: 1439px) {
    .contacts__wrapper {
      justify-self: center; } }
  @media (min-width: 1024px) {
    .contacts__wrapper {
      max-width: 960px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .contacts__wrapper {
      max-width: 640px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .contacts__wrapper {
      max-width: 480px; } }
  @media (max-width: 479px) {
    .contacts__wrapper {
      max-width: 360px; } }

@media (min-width: 1024px) {
  .contacts__title {
    margin: 0 0 80px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .contacts__title {
    margin: 0 0 40px; } }

@media (max-width: 767px) {
  .contacts__title {
    margin: 0 0 24px;
    padding-bottom: 21px; } }

.contacts__list {
  display: grid;
  padding: 0;
  list-style: none; }
  @media (min-width: 1024px) {
    .contacts__list {
      grid-template-columns: 1.35fr 1fr;
      grid-gap: 64px 20px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .contacts__list {
      grid-template-columns: 1.58fr 1fr;
      grid-gap: 40px 20px; } }
  @media (max-width: 767px) {
    .contacts__list {
      grid-template-columns: 1fr;
      grid-row-gap: 23px; } }

.item-contact {
  display: grid; }

.item-contact__title {
  justify-self: start;
  color: var(--accent-color);
  font-weight: 400;
  line-height: 160%; }
  @media (min-width: 1024px) {
    .item-contact__title {
      margin: 0 0 12px;
      font-size: 16px; } }
  @media (max-width: 1023px) {
    .item-contact__title {
      margin: 0 0 8px;
      font-size: 12px; } }
  @media (max-width: 767px) {
    .item-contact__title {
      font-size: 10px; } }

.item-contact__value {
  justify-self: start;
  color: #fff;
  font-weight: 700;
  line-height: 160%; }
  @media (min-width: 1024px) {
    .item-contact__value {
      font-size: 18px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .item-contact__value {
      font-size: 16px; } }
  @media (max-width: 767px) {
    .item-contact__value {
      font-size: 12px; } }

@media (min-width: 1024px) {
  .develop {
    padding: 80px 0; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .develop {
    padding: 60px 0; } }

@media (max-width: 767px) {
  .develop {
    padding: 20px 0 40px; } }

.develop__container {
  display: grid; }

.develop__wrapper {
  display: grid;
  width: 100%; }
  @media (max-width: 1439px) {
    .develop__wrapper {
      justify-self: center; } }
  @media (min-width: 1024px) {
    .develop__wrapper {
      max-width: 960px;
      padding: 0 0 0 80px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .develop__wrapper {
      max-width: 640px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .develop__wrapper {
      max-width: 480px; } }
  @media (max-width: 479px) {
    .develop__wrapper {
      max-width: 360px; } }

.develop__sub-title {
  max-width: 650px;
  margin: 0 0 64px; }
  @media (min-width: 1024px) {
    .develop__sub-title {
      margin: 0 0 64px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .develop__sub-title {
      margin: 0 0 44px;
      max-width: 520px; } }
  @media (max-width: 767px) {
    .develop__sub-title {
      margin: 0 0 24px; } }

.develop__list {
  display: grid;
  justify-content: space-between;
  margin: 0 0 40px;
  padding: 0;
  list-style: none; }
  @media (min-width: 1024px) {
    .develop__list {
      grid-template-columns: repeat(3, 240px);
      grid-gap: 40px 20px;
      max-width: 880px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .develop__list {
      grid-template-columns: repeat(3, 200px);
      grid-gap: 24px 20px;
      margin: 0 0 24px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .develop__list {
      grid-template-columns: repeat(3, 136px);
      grid-gap: 16px 10px;
      margin: 0 0 24px; } }
  @media (max-width: 479px) {
    .develop__list {
      grid-template-columns: repeat(2, 136px);
      grid-gap: 16px;
      justify-content: center;
      margin: 0 0 24px; } }

.develop__postscript {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80px;
  padding: 24px;
  color: #fff;
  font-weight: 700; }
  @media (min-width: 1024px) {
    .develop__postscript {
      font-size: 24px;
      line-height: 32px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .develop__postscript {
      font-size: 18px;
      line-height: 24px; } }
  @media (max-width: 767px) {
    .develop__postscript {
      font-size: 12px;
      line-height: 16px;
      text-align: center; } }
  .develop__postscript p {
    position: relative; }
  .develop__postscript .pattern {
    fill: #fff; }

.item-develop {
  display: grid; }
  @media (min-width: 1024px) {
    .item-develop {
      grid-template-rows: 240px 1fr; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .item-develop {
      grid-template-rows: 200px 1fr; } }
  @media (max-width: 767px) {
    .item-develop {
      grid-template-rows: 136px 1fr; } }

.item-develop__title {
  order: 2;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin: 16px 0 0; }
  @media (min-width: 1024px) {
    .item-develop__title {
      min-height: 64px;
      font-size: 24px;
      line-height: 32px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .item-develop__title {
      min-height: 48px;
      font-size: 18px;
      line-height: 24px; } }
  @media (max-width: 767px) {
    .item-develop__title {
      min-height: 32px;
      font-size: 12px;
      line-height: 16px;
      margin: 8px 0 0; } }

.item-develop__image-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 1;
  padding: 24px;
  border: 1px solid;
  border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.4) 49.92%, rgba(255, 255, 255, 0.12) 100%);
  border-image-slice: 1;
  transition: box-shadow 0.2s; }
  .item-develop__image-wrap:hover, .item-develop__image-wrap:focus {
    border: 1px solid var(--accent-color);
    box-shadow: 0 0 16px var(--accent-color); }

.item-develop__pattern {
  top: 8px;
  left: 8px;
  width: calc(100% - 16px);
  height: calc(100% - 16px); }

.item-develop__image {
  position: relative; }

.footer {
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  pointer-events: none; }
  .footer::before {
    content: "";
    position: absolute;
    width: 2224px;
    left: 50%;
    z-index: -1;
    background: radial-gradient(50% 50% at 50% 50%, #121238 0%, rgba(19, 19, 47, 0) 100%);
    pointer-events: none;
    transform: translateX(-50%); }
  @media (min-width: 1024px) {
    .footer {
      padding: 40px 0; }
      .footer::before {
        bottom: -280px;
        height: 520px; } }
  @media (max-width: 1023px) {
    .footer {
      padding: 24px 0; }
      .footer::before {
        bottom: -400px;
        height: 500px; } }
  @media (max-width: 767px) {
    .footer {
      display: none; } }

.footer__container {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 20px;
  align-items: center;
  justify-content: space-between;
  max-width: 1820px; }

.footer__socials {
  pointer-events: visible; }

.footer__download {
  pointer-events: visible; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  box-sizing: border-box;
  width: 100%;
  max-width: 100vw; }
  .header::before {
    content: "";
    position: absolute;
    width: 2224px;
    left: 50%;
    z-index: -1;
    background: radial-gradient(50% 50% at 50% 50%, #121238 0%, rgba(19, 19, 47, 0) 100%);
    pointer-events: none;
    transform: translateX(-50%); }
  @media (min-width: 1024px) {
    .header {
      padding: 40px 0 0; }
      .header::before {
        top: -280px;
        height: 520px; } }
  @media (min-width: 480px) and (max-width: 1023px) {
    .header {
      padding: 24px 0; }
      .header::before {
        top: -250px;
        height: 500px; } }
  @media (max-width: 479px) {
    .header {
      padding: 16px 0; }
      .header::before {
        top: -102px;
        height: 204px; } }

.header__container {
  display: grid;
  grid-column-gap: 20px;
  align-items: center;
  max-width: 1820px; }
  @media (min-width: 1440px) {
    .header__container {
      grid-template-columns: minmax(max-content, 1fr) minmax(max-content, 220px) minmax(max-content, 356px) max-content; } }
  @media (min-width: 1024px) and (max-width: 1439px) {
    .header__container {
      grid-template-columns: 60px minmax(max-content, 1fr) minmax(max-content, 185px) minmax(max-content, 185px) max-content; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .header__container {
      grid-template-columns: 60px minmax(max-content, 1fr) minmax(max-content, 185px) max-content; } }
  @media (max-width: 767px) {
    .header__container {
      grid-template-columns: 48px max-content 48px;
      justify-content: space-between; } }

@media (min-width: 1440px) {
  .header__burger {
    display: none; } }

@media (max-width: 1439px) {
  .header__burger {
    position: relative;
    z-index: 50;
    display: flex;
    padding: 0;
    border: none;
    background: none; }
    .header__burger .pattern {
      fill: #fff; }
    .header__burger--closed span {
      position: absolute;
      height: 1px;
      background-color: #fff;
      transition: 0.2s width; }
    .header__burger--opened span {
      position: absolute;
      top: 50%;
      height: 1px;
      background-color: #fff; }
    .header__burger--opened span:nth-child(1) {
      transform: translateY(-50%) rotate(-45deg); }
    .header__burger--opened span:nth-child(2) {
      display: none; }
    .header__burger--opened span:nth-child(3) {
      transform: translateY(-50%) rotate(45deg); } }

@media (min-width: 1024px) and (max-width: 1439px) {
  .header__burger {
    left: -30px; } }

@media (min-width: 768px) and (max-width: 1439px) {
  .header__burger {
    width: 64px;
    height: 48px; }
    .header__burger--opened span {
      right: 16px;
      width: 23px; }
    .header__burger--closed span {
      right: 16px; }
    .header__burger--closed span:nth-child(1) {
      top: 15px;
      width: 24px; }
    .header__burger--closed span:nth-child(2) {
      top: 23px;
      width: 16px; }
    .header__burger--closed span:nth-child(3) {
      bottom: 15px;
      width: 24px; }
    .header__burger--closed:hover span:nth-child(2), .header__burger--closed:focus span:nth-child(2) {
      width: 24px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .header__burger {
    left: -24px; } }

@media (max-width: 767px) {
  .header__burger {
    width: 48px;
    height: 32px; }
    .header__burger--opened span {
      right: 8px;
      width: 14px; }
    .header__burger--closed span {
      right: 8px; }
    .header__burger--closed span:nth-child(1) {
      top: 9px;
      width: 16px; }
    .header__burger--closed span:nth-child(2) {
      top: 15px;
      width: 8px; }
    .header__burger--closed span:nth-child(3) {
      bottom: 9px;
      width: 16px; }
    .header__burger--closed:hover span:nth-child(2), .header__burger--closed:focus span:nth-child(2) {
      width: 16px; } }

@media (min-width: 480px) and (max-width: 767px) {
  .header__burger {
    left: -24px; } }

@media (max-width: 479px) {
  .header__burger {
    left: -16px; } }

.header__logo {
  z-index: 50; }
  @media (min-width: 1024px) {
    .header__logo {
      justify-self: start; } }
  @media (min-width: 480px) and (max-width: 1023px) {
    .header__logo {
      width: 120px; } }
  @media (max-width: 479px) {
    .header__logo {
      width: 96px; } }

.header__phone {
  z-index: 50; }
  @media (min-width: 1024px) {
    .header__phone {
      justify-self: start;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      text-transform: uppercase; } }
  @media (max-width: 1023px) {
    .header__phone {
      display: none; } }

.header__recall {
  z-index: 50; }
  @media (min-width: 768px) {
    .header__recall {
      justify-self: start; } }
  @media (max-width: 767px) {
    .header__recall {
      position: relative;
      width: 48px;
      height: 32px;
      background-color: transparent;
      background-image: url("../img/icons/phone.svg");
      background-position: 12px 7px;
      background-repeat: no-repeat;
      font-size: 0; }
      .header__recall::before {
        display: none; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .header__recall {
      right: -24px; } }
  @media (max-width: 479px) {
    .header__recall {
      right: -16px; } }
  .header__recall .pattern {
    display: none;
    clip-path: none; }
    @media (max-width: 767px) {
      .header__recall .pattern {
        display: block; } }

.header__discuss {
  z-index: 50; }
  @media (min-width: 768px) {
    .header__discuss {
      justify-self: end; } }
  @media (max-width: 767px) {
    .header__discuss {
      display: none; } }

.nav {
  position: relative;
  z-index: 40;
  display: grid;
  opacity: 0;
  pointer-events: none; }
  @media (max-width: 1439px) {
    .nav--opened {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      max-height: 100%;
      background: rgba(35, 22, 71, 0.6);
      backdrop-filter: blur(40px);
      opacity: 1;
      pointer-events: visible; } }

@media (max-width: 767px) {
  .nav__background {
    position: absolute;
    width: 100%;
    height: 64px;
    top: 0;
    left: 0;
    transition: transform 0.2s;
    z-index: 40;
    transform: translateX(-100%);
    background: #231647;
    backdrop-filter: blur(40px); } }

.nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  min-height: 100%;
  height: 100%;
  justify-self: start;
  padding: 184px 30px 120px 24px;
  background: rgba(35, 22, 71, 0.6);
  backdrop-filter: blur(40px);
  transition: transform 0.2s;
  transform: translateX(-100%); }
  @media (max-height: 767px) {
    .nav__wrapper {
      padding: 120px 40px 120px 24px; } }
  @media (min-width: 768px) {
    .nav__wrapper {
      min-width: 304px; } }
  @media (max-width: 767px) {
    .nav__wrapper {
      width: 100%;
      padding: 88px 30px 35px 40px; }
      .nav__wrapper::-webkit-scrollbar {
        width: 0; }
      .nav__wrapper::-webkit-scrollbar-thumb {
        height: 0; } }

.nav__list {
  position: relative;
  display: grid;
  grid-row-gap: 24px;
  max-width: 320px;
  margin: 0 auto;
  padding: 0 10px 0 0;
  list-style: none; }
  .nav__list::before {
    content: "";
    order: 50;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 34.9%, rgba(255, 255, 255, 0) 100%); }
  @media (min-width: 768px) {
    .nav__list {
      overflow-y: auto;
      max-height: 100%; } }
  @media (max-width: 767px) {
    .nav__list::before {
      bottom: -15px; } }

.nav__item {
  display: grid;
  grid-template-columns: 1fr 24px;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center; }
  .nav__item.color-green {
    color: var(--color-green); }
    .nav__item.color-green .nav__sub-item::before {
      border-color: var(--color-green); }
    @media (hover: hover) {
      .nav__item.color-green a:hover {
        color: var(--color-green);
        filter: drop-shadow(0 0 8px var(--color-green)); }
        .nav__item.color-green a:hover .nav__name {
          color: var(--color-green); } }
  .nav__item.color-purple {
    color: var(--color-purple); }
    .nav__item.color-purple .nav__sub-item::before {
      border-color: var(--color-purple); }
    @media (hover: hover) {
      .nav__item.color-purple a:hover {
        color: var(--color-purple);
        filter: drop-shadow(0 0 8px var(--color-purple)); }
        .nav__item.color-purple a:hover .nav__name {
          color: var(--color-purple); } }
  .nav__item.color-yellow {
    color: var(--color-yellow); }
    .nav__item.color-yellow .nav__sub-item::before {
      border-color: var(--color-yellow); }
    @media (hover: hover) {
      .nav__item.color-yellow a:hover {
        color: var(--color-yellow);
        filter: drop-shadow(0 0 8px var(--color-yellow)); }
        .nav__item.color-yellow a:hover .nav__name {
          color: var(--color-yellow); } }
  .nav__item.color-pink {
    color: var(--color-pink); }
    .nav__item.color-pink .nav__sub-item::before {
      border-color: var(--color-pink); }
    @media (hover: hover) {
      .nav__item.color-pink a:hover {
        color: var(--color-pink);
        filter: drop-shadow(0 0 8px var(--color-pink)); }
        .nav__item.color-pink a:hover .nav__name {
          color: var(--color-pink); } }
  @media (hover: hover) {
    .nav__item a {
      transition: var(--transition-main);
      transition-property: filter, color;
      will-change: filter; } }

.nav__link {
  display: grid;
  align-items: center; }
  @media (min-width: 480px) {
    .nav__link {
      grid-template-columns: 40px max-content;
      grid-column-gap: 40px; } }
  @media (max-width: 479px) {
    .nav__link {
      grid-template-columns: 24px max-content;
      grid-column-gap: 24px; } }

.nav__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 50%; }
  @media (min-width: 480px) {
    .nav__icon {
      width: 40px;
      height: 40px; } }
  @media (max-width: 479px) {
    .nav__icon {
      width: 24px;
      height: 24px; } }

.nav__name {
  color: #fff;
  font-weight: 500;
  text-transform: uppercase; }
  @media (min-width: 480px) {
    .nav__name {
      font-size: 16px;
      line-height: 20px; } }
  @media (max-width: 479px) {
    .nav__name {
      font-size: 12px;
      line-height: 16px; } }

.nav__sub-open {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  padding: 0;
  height: 24px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: transparent; }
  .nav__sub-open::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 1px;
    background-color: #fff; }
  .nav__sub-open::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 8px;
    background-color: #fff; }
  .nav__sub-open--opened::after {
    display: none; }

.nav__sub-list {
  display: none; }
  .nav__sub-list--opened {
    display: grid;
    grid-row: 2/3;
    grid-column: 1/-1;
    grid-row-gap: 8px;
    list-style: none; }
  @media (min-width: 480px) {
    .nav__sub-list--opened {
      padding: 8px 0 0; } }
  @media (max-width: 479px) {
    .nav__sub-list--opened {
      padding: 16px 0 0; } }

.nav__sub-item {
  position: relative;
  display: grid; }
  .nav__sub-item::before {
    content: "";
    position: absolute;
    top: 50%;
    width: 8px;
    height: 8px;
    border: 1px solid var(--color-green);
    border-radius: 50%;
    transform: translateY(-50%); }
  @media (min-width: 480px) {
    .nav__sub-item {
      padding: 0 0 0 80px; }
      .nav__sub-item::before {
        left: 16px; } }
  @media (max-width: 479px) {
    .nav__sub-item {
      padding: 0 0 0 48px; }
      .nav__sub-item::before {
        left: 8px; } }

.nav__sub-link {
  color: #fff;
  font-weight: 500; }
  @media (min-width: 480px) {
    .nav__sub-link {
      padding: 10px 0;
      font-size: 16px;
      line-height: 20px; } }
  @media (max-width: 479px) {
    .nav__sub-link {
      font-size: 12px;
      padding: 4px 0;
      line-height: 16px; } }

@media (min-width: 768px) {
  .nav__footer {
    display: none; } }

@media (max-width: 767px) {
  .nav__footer {
    position: relative;
    display: grid;
    justify-items: center;
    max-width: 320px;
    margin: 0 auto; }
    .nav__footer::before {
      content: "";
      position: absolute;
      bottom: 72px;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 34.9%, rgba(255, 255, 255, 0) 100%); } }

@media (max-width: 479px) {
  .nav__footer::before {
    bottom: 64px; } }

.nav__discuss {
  max-width: 192px;
  width: 100%;
  margin: 40px 0 24px; }
  @media (max-width: 479px) {
    .nav__discuss {
      margin: 56px 0 26px -10px; } }

.nav__download {
  margin: 0 0 40px; }
  @media (max-width: 479px) {
    .nav__download {
      margin: 0 0 40px -10px; }
      .nav__download::before {
        bottom: -3px; } }

.nav__socials {
  justify-content: space-between;
  width: 100%;
  max-width: 96px;
  padding: 40px 0 0; }
  @media (max-width: 479px) {
    .nav__socials {
      padding: 43px 0 0;
      margin: 0 0 0 -10px; } }

.modal {
  overflow: hidden; }
  .modal[aria-hidden="true"] {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: grid;
    overflow-y: auto;
    width: 100%;
    min-width: 320px;
    height: 100%;
    max-height: 100%; }
  .modal[aria-hidden="false"] {
    display: none; }

.modal__wrapper {
  display: grid;
  align-items: center;
  justify-items: center;
  background: rgba(35, 22, 71, 0.85);
  backdrop-filter: blur(40px); }
  @media (min-width: 768px) {
    .modal__wrapper {
      padding: 5px; } }
  @media (max-width: 767px) {
    .modal__wrapper {
      padding: 8px;
      max-height: 100%; } }

.modal__container {
  display: grid;
  width: 100%;
  max-width: 575px;
  opacity: 0;
  padding-right: 56px;
  transition: opacity 0.2s, transform 0.2s;
  transform: translateY(-100px); }
  @media (max-width: 767px) {
    .modal__container {
      padding: 0;
      max-width: 100%;
      height: 100%;
      min-height: 100%; } }

.modal__close {
  right: -55px;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
  justify-self: flex-end;
  width: 40px;
  height: 40px;
  margin: 0 0 15px;
  padding: 0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: transparent;
  color: #fff;
  fill: #fff; }
  .modal__close::before, .modal__close::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #fff;
    pointer-events: none; }
  .modal__close::before {
    transform: rotate(-45deg); }
  .modal__close::after {
    transform: rotate(45deg); }
  @media (max-width: 1023px) {
    .modal__close {
      right: -54px; } }
  @media (max-width: 767px) {
    .modal__close {
      margin: 0;
      top: 16px;
      right: 16px;
      position: absolute;
      width: 32px;
      height: 32px; } }

.telegram {
  position: relative;
  max-width: 1086px; }
  @media (min-width: 1024px) {
    .telegram::after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -28px;
      bottom: 32px;
      width: 1px;
      z-index: 2;
      height: 582px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0), white 34.9%, rgba(255, 255, 255, 0)); } }
  @media (max-width: 1023px) {
    .telegram {
      max-width: 575px; } }
  @media (max-width: 767px) {
    .telegram {
      max-width: 100%; } }
  .telegram .form-modal {
    grid-template-columns: 1fr 1fr;
    max-width: 100%; }
    @media (max-width: 1023px) {
      .telegram .form-modal {
        grid-template-columns: 1fr; } }
  @media (min-width: 620px) {
    .telegram .telegram__wrapper {
      display: grid;
      grid-template-columns: 178px 1fr;
      grid-column-gap: 40px; } }
  @media (max-width: 1023px) {
    .telegram .telegram__wrapper {
      margin-top: 40px;
      grid-template-columns: 160px 1fr;
      grid-column-gap: 32px; } }
  .telegram .form-modal__fieldset {
    padding-right: 32px;
    max-width: 400px; }
    @media (max-width: 1023px) {
      .telegram .form-modal__fieldset {
        padding-right: 0;
        max-width: 368px; } }

.telegram__wrapper {
  display: none; }

.telegram__title {
  position: relative;
  justify-self: center;
  padding-top: 80px;
  font-weight: 700;
  grid-column: 1/-1;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  color: #FFFFFF;
  max-width: 300px;
  margin: 0 0 40px; }
  .telegram__title::before {
    content: "";
    position: absolute;
    width: 64px;
    height: 64px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("../img/telegram.png");
    background-size: cover;
    background-repeat: no-repeat; }
  @media (max-width: 1023px) {
    .telegram__title {
      max-width: 100%;
      padding-top: 40px;
      padding-left: 56px;
      text-align: left;
      font-size: 24px;
      line-height: 31px;
      margin: 0 0 16px; }
      .telegram__title::before {
        width: 40px;
        height: 40px;
        top: auto;
        bottom: -4px;
        left: 0;
        transform: translate(0, 0); } }

.telegram__image-wrapper {
  position: relative;
  display: flex;
  width: 176px;
  height: 176px;
  grid-column: 1/2;
  margin: 0 0 70px;
  padding: 8px; }
  .telegram__image-wrapper::before {
    content: "";
    top: 16px;
    left: 16px;
    z-index: -1;
    position: absolute;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    border: 1px solid;
    border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.08) 0%, #fff 49.92%, rgba(255, 255, 255, 0.08) 100%);
    border-image-slice: 1;
    transition: box-shadow 0.2s; }
  .telegram__image-wrapper:hover::before, .telegram__image-wrapper:focus-within::before {
    border: 1px solid var(--accent-color);
    box-shadow: 0 0 16px var(--accent-color); }
  @media (max-width: 1023px) {
    .telegram__image-wrapper {
      width: 110px;
      height: 110px;
      padding: 4px;
      margin: 0 0 40px;
      justify-self: end; }
      .telegram__image-wrapper::before {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        top: 8px;
        left: 8px; } }

.telegram__image {
  position: absolute;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  object-fit: cover; }
  @media (max-width: 1023px) {
    .telegram__image {
      width: calc(100% - 8px);
      height: calc(100% - 8px); } }

.telegram__info {
  align-content: center;
  margin: 0 0 70px;
  display: grid;
  grid-column: 2/3; }
  @media (max-width: 1023px) {
    .telegram__info {
      margin: 0 0 40px;
      max-width: 170px; } }

.telegram__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 160%;
  color: #FFFFFF; }
  @media (max-width: 1023px) {
    .telegram__name {
      font-size: 16px;
      line-height: 160%; } }

.telegram__position {
  margin: 0 0 8px;
  font-size: 14px;
  line-height: 160%;
  color: var(--accent-color); }
  @media (max-width: 1023px) {
    .telegram__position {
      font-size: 12px;
      margin: 0; } }

.telegram__site {
  font-size: 18px;
  line-height: 160%;
  margin: 0 0 8px; }
  @media (max-width: 1023px) {
    .telegram__site {
      font-size: 16px;
      margin: 0; } }

.telegram__phone {
  font-size: 18px;
  line-height: 160%; }
  @media (max-width: 1023px) {
    .telegram__phone {
      font-size: 16px; } }

.telegram__link {
  grid-column: 1/-1;
  align-self: end;
  justify-self: center;
  min-width: 248px; }

.telegram__decor {
  top: 1px;
  left: 0;
  z-index: -1; }
  @media (max-width: 1024px) {
    .telegram__decor {
      top: -2px;
      left: -2px; } }
  @media (max-width: 767px) {
    .telegram__decor {
      top: -4px;
      left: 0px; } }

.telegram__small-link {
  display: none; }

@media (min-width: 620px) {
  .telegram .telegram__small-link {
    display: none; } }

@media (max-width: 619px) {
  .telegram .telegram__small-link {
    display: flex;
    position: relative;
    margin-top: 24px;
    padding-left: 24px;
    padding-bottom: 10px; }
    .telegram .telegram__small-link::after {
      top: 2px;
      left: 0;
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      background-image: url("../img/telegram.png");
      background-repeat: no-repeat;
      background-size: cover; } }

.form-modal {
  position: relative;
  display: grid;
  justify-items: center;
  max-width: 576px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4); }
  .form-modal > .pattern {
    top: 16px;
    left: 16px;
    pointer-events: none; }
  @media (min-width: 768px) {
    .form-modal {
      padding: 72px 32px 40px; }
      .form-modal::before {
        content: "";
        position: absolute;
        top: -10px;
        right: -21px;
        width: 23px;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.4);
        transform: rotate(-45deg); } }
  @media (max-width: 767px) {
    .form-modal {
      max-width: 100%;
      min-height: 100%;
      align-items: center; }
      .form-modal::before {
        content: "";
        position: absolute;
        top: 7px;
        right: -5px;
        width: 24px;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.4);
        transform: rotate(-45deg); }
      .form-modal > .pattern {
        top: 8px;
        left: 8px;
        width: calc(100% - 16px);
        min-height: 100%;
        height: calc(100% - 16px); } }
  @media (max-width: 767px) {
    .form-modal {
      padding: 38px 32px; } }

.form-modal__wrapper {
  margin: 0 0 14px; }

.form-modal__decor {
  position: absolute;
  top: -9px;
  left: -9px;
  color: var(--accent-color); }
  @media (max-width: 767px) {
    .form-modal__decor {
      display: none; } }

.form-modal__fieldset {
  position: relative;
  display: grid;
  margin: 0;
  padding: 0;
  border: none; }
  @media (min-width: 768px) {
    .form-modal__fieldset {
      max-width: 368px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .form-modal__fieldset {
      max-width: 320px; } }
  @media (max-width: 479px) {
    .form-modal__fieldset {
      max-width: 368px; } }

.form-modal__legend {
  margin: 0 0 8px;
  color: #fff;
  font-weight: 700;
  text-align: center; }
  @media (min-width: 768px) {
    .form-modal__legend {
      font-size: 40px;
      line-height: 52px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .form-modal__legend {
      font-size: 32px;
      line-height: 44px; } }
  @media (max-width: 479px) {
    .form-modal__legend {
      font-size: 18px;
      line-height: 24px;
      text-align: left;
      margin: 0 0 8px; } }

.form-modal__text {
  color: #fff;
  line-height: 160%;
  text-align: center; }
  @media (min-width: 768px) {
    .form-modal__text {
      width: calc(100% + 60px);
      margin: 0 -30px 24px;
      font-size: 18px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .form-modal__text {
      width: calc(100% + 40px);
      margin: 0 -20px 16px;
      font-size: 16px; } }
  @media (max-width: 479px) {
    .form-modal__text {
      width: calc(100% + 20px);
      margin: 0 0 16px;
      font-size: 12px;
      line-height: 20px;
      text-align: left; } }

.form-modal__checkbox {
  padding: 14px 0 0; }
  @media (min-width: 768px) {
    .form-modal__checkbox {
      width: calc(100% + 40px);
      margin: 0 -40px 0 0; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .form-modal__checkbox {
      width: calc(100% + 20px);
      margin: 0 -20px 0 0; } }
  @media (max-width: 767px) {
    .form-modal__checkbox {
      padding: 14px 0 0; } }

.form-modal__button {
  justify-self: center;
  margin: 0; }

.form__checkbox {
  display: grid;
  grid-row-gap: 8px;
  margin: 0 0 40px; }
  @media (max-width: 479px) {
    .form__checkbox {
      margin: 0 0 24px;
      grid-row-gap: 24px; } }

.form__checkbox-wrapper {
  display: grid; }

.form__label-checkbox {
  position: relative;
  color: #fff;
  line-height: 160%;
  cursor: pointer;
  padding: 0 0 0 32px;
  transition: box-shadow 0.2s, border 0.2s; }
  .form__label-checkbox::before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    border: 1px solid #fff;
    background-color: transparent; }
  .form__label-checkbox a {
    color: var(--accent-color);
    text-decoration: underline; }
  @media (min-width: 768px) {
    .form__label-checkbox {
      font-size: 14px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .form__label-checkbox {
      font-size: 12px; } }
  @media (max-width: 479px) {
    .form__label-checkbox {
      font-size: 10px;
      margin-right: -5px; } }

.form__checkbox-wrapper._error .form__label-checkbox::before {
  border: 1px solid var(--error-color);
  box-shadow: 0 0 15px var(--error-color); }

.form__checkbox input:checked ~ .form__label-checkbox::before {
  background-color: #fff;
  border: 5px solid var(--accent-color); }

.modal__success {
  display: none; }
  .modal__success--opened {
    position: relative;
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100%;
    max-width: 392px;
    min-height: 206px;
    padding: 32px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s; }

.modal__success-decor {
  position: absolute;
  top: -9px;
  left: -9px;
  color: var(--accent-color); }

.modal__success-text {
  max-width: 200px;
  margin: 0;
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center; }

.modal__success .pattern {
  top: 16px;
  left: 16px; }

.modal__success-ball {
  position: absolute;
  width: 100%;
  height: 100%; }
  .modal__success-ball::before {
    content: "";
    position: absolute;
    background-repeat: no-repeat; }
  .modal__success-ball--01::before {
    top: 94px;
    left: -50px;
    width: 80px;
    height: 56px;
    background-image: url("../img/modal/ball01.png"); }
  .modal__success-ball--02::before {
    top: 220px;
    left: 110px;
    width: 44px;
    height: 31px; }
  .modal__success-ball--03::before {
    top: -38px;
    left: 226px;
    width: 32px;
    height: 32px;
    background-image: url("../img/modal/ball03.png"); }
  .modal__success-ball--04::before {
    bottom: 12px;
    right: 48px;
    width: 54px;
    height: 38px;
    background-image: url("../img/modal/ball04.png"); }
  .modal__success-ball--05::before {
    top: 10px;
    right: -40px;
    width: 96px;
    height: 67px; }
  @media (max-width: 479px) {
    .modal__success-ball--01::before {
      left: -10px; }
    .modal__success-ball--05::before {
      right: -10px; } }

html[data-theme="green"] .modal__success-ball--02::before {
  background-image: url("../img/modal/ball02.png"); }

html[data-theme="green"] .modal__success-ball--05::before {
  background-image: url("../img/modal/ball05.png"); }

html[data-theme="purple"] .modal__success-ball--02::before {
  background-image: url("../img/modal/ball02-fiol.png"); }

html[data-theme="purple"] .modal__success-ball--05::before {
  background-image: url("../img/modal/ball05-fiol.png"); }

html[data-theme="yellow"] .modal__success-ball--02::before {
  background-image: url("../img/modal/ball02-yellow.png"); }

html[data-theme="yellow"] .modal__success-ball--05::before {
  background-image: url("../img/modal/ball05-yellow.png"); }

html[data-theme="pink"] .modal__success-ball--02::before {
  background-image: url("../img/modal/ball02-purp.png"); }

html[data-theme="pink"] .modal__success-ball--05::before {
  background-image: url("../img/modal/ball05-purp.png"); }

.tg-modal {
  max-width: 567px; }
  @media (max-width: 1023px) {
    .tg-modal .telegram__title {
      display: block;
      position: relative;
      justify-self: center;
      padding-top: 80px;
      padding-left: 0;
      font-weight: 700;
      grid-column: 1/-1;
      font-size: 40px;
      line-height: 52px;
      text-align: center;
      color: #FFFFFF;
      max-width: 300px;
      margin: 0 auto 40px; }
      .tg-modal .telegram__title::before {
        content: "";
        position: absolute;
        width: 64px;
        height: 64px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-image: url("../img/telegram.png");
        background-size: cover;
        background-repeat: no-repeat; } }
  @media (max-width: 767px) {
    .tg-modal .telegram__title {
      padding-top: 56px;
      font-size: 18px;
      line-height: 23px; }
      .tg-modal .telegram__title::before {
        width: 40px;
        height: 40px; } }

.tg-modal .form-modal {
  display: none; }

.tg-modal__container {
  display: none; }

.tg-modal .tg-modal__container {
  position: relative;
  display: grid;
  justify-items: center;
  max-width: 512px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4); }
  .tg-modal .tg-modal__container > .pattern {
    top: 16px;
    left: 16px;
    pointer-events: none; }
  @media (min-width: 768px) {
    .tg-modal .tg-modal__container {
      padding: 56px 32px 40px; }
      .tg-modal .tg-modal__container::before {
        content: "";
        position: absolute;
        top: -10px;
        right: -21px;
        width: 23px;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.4);
        transform: rotate(-45deg); } }
  @media (max-width: 767px) {
    .tg-modal .tg-modal__container {
      max-width: 100%;
      min-height: 100%;
      align-items: center; }
      .tg-modal .tg-modal__container::before {
        content: "";
        position: absolute;
        top: 7px;
        right: -5px;
        width: 24px;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.4);
        transform: rotate(-45deg); }
      .tg-modal .tg-modal__container > .pattern {
        top: 8px;
        left: 8px;
        width: calc(100% - 16px);
        min-height: 100%;
        height: calc(100% - 16px); } }
  @media (max-width: 767px) {
    .tg-modal .tg-modal__container {
      padding: 84px 24px 20px; } }

.tg-modal .telegram__wrapper {
  display: grid;
  grid-template-columns: 178px 1fr;
  grid-column-gap: 40px; }
  @media (max-width: 767px) {
    .tg-modal .telegram__wrapper {
      grid-template-columns: 1fr;
      grid-column-gap: 0px; } }

.tg-modal .telegram__image-wrapper {
  margin: 0 0 48px; }
  @media (max-width: 1023px) {
    .tg-modal .telegram__image-wrapper {
      position: relative;
      display: flex;
      width: 176px;
      height: 176px;
      grid-column: 1/2;
      margin: 0 0 48px;
      padding: 8px; }
      .tg-modal .telegram__image-wrapper::before {
        content: "";
        top: 16px;
        left: 16px;
        z-index: -1;
        position: absolute;
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        border: 1px solid;
        border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.08) 0%, #fff 49.92%, rgba(255, 255, 255, 0.08) 100%);
        border-image-slice: 1;
        transition: box-shadow 0.2s; } }
  @media (max-width: 767px) {
    .tg-modal .telegram__image-wrapper {
      width: 132px;
      height: 132px;
      padding: 4px;
      margin: 0 0 8px;
      justify-self: end;
      grid-column: 1/-1;
      justify-self: center; }
      .tg-modal .telegram__image-wrapper::before {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        top: 8px;
        left: 8px; } }

@media (max-width: 1023px) {
  .tg-modal .telegram__image {
    position: absolute;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    object-fit: cover; } }

@media (max-width: 767px) {
  .tg-modal .telegram__image {
    width: calc(100% - 12px);
    height: calc(100% - 12px); } }

.tg-modal .telegram__info {
  margin: 0 0 48px; }
  @media (max-width: 1023px) {
    .tg-modal .telegram__info {
      align-content: center;
      margin: 0 0 48px;
      display: grid;
      grid-column: 2/3; } }
  @media (max-width: 767px) {
    .tg-modal .telegram__info {
      margin: 0 0 40px;
      max-width: 170px;
      grid-column: 1/-1;
      justify-self: center;
      justify-items: center; } }

@media (max-width: 1023px) {
  .tg-modal .telegram__name {
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    color: #FFFFFF; } }

@media (max-width: 767px) {
  .tg-modal .telegram__name {
    font-size: 16px;
    line-height: 160%; } }

@media (max-width: 1023px) {
  .tg-modal .telegram__position {
    margin: 0 0 8px;
    font-size: 14px;
    line-height: 160%;
    color: var(--accent-color); } }

@media (max-width: 767px) {
  .tg-modal .telegram__position {
    font-size: 12px;
    margin: 0 0 8px; } }

@media (max-width: 1023px) {
  .tg-modal .telegram__site {
    font-size: 18px;
    line-height: 160%;
    margin: 0 0 8px; } }

@media (max-width: 767px) {
  .tg-modal .telegram__site {
    font-size: 16px;
    margin: 0; } }

@media (max-width: 1023px) {
  .tg-modal .telegram__phone {
    font-size: 18px;
    line-height: 160%; } }

@media (max-width: 767px) {
  .tg-modal .telegram__phone {
    font-size: 16px; } }

@media (max-width: 1023px) {
  .tg-modal .telegram__decor {
    top: 2px;
    left: 2px;
    z-index: -1; } }

@media (max-width: 767px) {
  .tg-modal .telegram__decor {
    top: -4px;
    left: 0px; } }

@media (max-width: 767px) {
  .tg-modal .telegram__link {
    min-width: 168px; } }

@media (min-width: 768px) {
  .offer-index__form {
    position: relative;
    grid-template-columns: minmax(200px, 365px) minmax(400px, 1fr); } }

@media (max-width: 767px) {
  .offer-index__form {
    grid-template-columns: 60% 40%; } }

@media (max-width: 767px) {
  .offer-index__button {
    justify-self: start;
    margin: 24px 0 0; } }

@media (min-width: 768px) {
  .offer-index__button-phone {
    display: flex; } }

@media (max-width: 767px) {
  .offer-index__button-phone {
    display: none; } }

@media (min-width: 768px) {
  .offer-index__button-form {
    display: none; } }

@media (max-width: 767px) {
  .offer-index__button-form {
    display: flex; } }

.offer-index__wrapper-name {
  margin-right: -2px; }

@media (min-width: 768px) {
  .offer-project__form {
    position: relative;
    grid-template-columns: minmax(200px, 434px) minmax(400px, 1fr); } }

@media (max-width: 767px) {
  .offer-project__form {
    grid-template-columns: 60% 40%; } }

@media (max-width: 767px) {
  .offer-project__button {
    justify-self: start;
    margin: 24px 0 0; } }

@media (min-width: 768px) {
  .offer-project__button-phone {
    display: flex; } }

@media (max-width: 767px) {
  .offer-project__button-phone {
    display: none; } }

@media (min-width: 768px) {
  .offer-project__button-form {
    display: none; } }

@media (max-width: 767px) {
  .offer-project__button-form {
    display: flex; } }

.offer-project__wrapper-name {
  margin-right: -2px; }

@media (min-width: 1024px) {
  .offer-team__form {
    grid-row-gap: 11px;
    grid-template-columns: minmax(240px, 320px) minmax(240px, 250px) minmax(240px, 310px); } }

@media (min-width: 768px) and (max-width: 1023px) {
  .offer-team__form {
    grid-row-gap: 32px;
    grid-template-columns: minmax(100px, 240px) minmax(100px, 160px) minmax(100px, 240px); } }

@media (max-width: 767px) {
  .offer-team__form {
    grid-template-columns: 58% 42%;
    grid-row-gap: 8px; } }

.offer-team__wrapper--file {
  grid-column: 1/-1;
  justify-content: space-between; }

.offer-team__wrapper--name {
  margin-right: -2px; }

.offer-team__wrapper--phone {
  margin-right: -2px; }

@media (max-width: 767px) {
  .offer-team__wrapper--email {
    grid-column: 1/-1; }
  .offer-team__wrapper--file {
    flex-direction: column;
    padding: 0;
    border-bottom: none; }
    .offer-team__wrapper--file::before, .offer-team__wrapper--file::after {
      display: none; } }

main {
  display: grid; }

.page404 {
  display: grid;
  align-items: center;
  padding: 200px 0; }
  @media (max-width: 767px) {
    .page404 {
      padding: 100px 0; } }

.page404__container {
  display: grid; }

.page404__wrapper {
  display: grid;
  max-width: 960px; }
  @media (max-width: 1439px) {
    .page404__wrapper {
      max-width: 100%; } }

.page404__title {
  justify-self: center;
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  margin: 0 0 40px;
  color: #fff; }
  .page404__title span {
    font-size: 200px;
    line-height: 200px;
    color: var(--accent-color); }
  @media (max-width: 479px) {
    .page404__title {
      font-size: 24px; }
      .page404__title span {
        font-size: 100px;
        line-height: 100px; } }

.page404__return {
  justify-self: center; }
  @media (max-width: 767px) {
    .page404__return {
      font-size: 16px;
      line-height: 20px; } }

.preview {
  position: relative;
  display: grid;
  align-content: center;
  height: 100vh; }
  @media (min-width: 1024px) {
    .preview {
      min-height: 680px; } }
  @media (max-width: 1023px) {
    .preview {
      min-height: 480px; } }

.preview__container {
  display: grid; }

.preview__wrapper {
  position: relative;
  display: grid;
  width: 100%; }
  @media (max-width: 1439px) {
    .preview__wrapper {
      justify-self: center; } }
  @media (min-width: 1024px) {
    .preview__wrapper {
      max-width: 960px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .preview__wrapper {
      max-width: 640px; } }
  @media (min-width: 768px) and (max-width: 1023px) and (min-height: 720px) {
    .preview__wrapper {
      top: -110px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .preview__wrapper {
      max-width: 480px; } }
  @media (min-width: 480px) and (max-width: 767px) and (min-height: 720px) {
    .preview__wrapper {
      top: -110px; } }
  @media (max-width: 479px) {
    .preview__wrapper {
      max-width: 360px;
      top: -20px; } }

.preview__title {
  width: 100%; }
  @media (min-width: 1024px) {
    .preview__title {
      margin: 0 0 64px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .preview__title {
      margin: 0 0 40px; } }
  @media (max-width: 767px) {
    .preview__title {
      margin: 0 0 24px;
      padding-bottom: 20px; } }

.preview__form-description {
  max-width: 700px;
  margin: 0 0 14px;
  padding: 0 80px;
  color: #fff;
  line-height: 160%; }
  @media (min-width: 1024px) {
    .preview__form-description {
      padding: 0 80px;
      font-size: 18px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .preview__form-description {
      padding: 0 120px 0 40px;
      font-size: 16px;
      margin: 0 0 24px; } }
  @media (max-width: 767px) {
    .preview__form-description {
      padding: 0 24px;
      font-size: 12px;
      margin: 0 0 24px; } }

.preview__form {
  width: 100%;
  max-width: 720px; }
  @media (min-width: 1024px) {
    .preview__form {
      padding: 0 80px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .preview__form {
      padding: 0 40px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .preview__form {
      padding: 0 24px; } }

@media (max-width: 479px) {
  .preview__button {
    min-width: 104px; } }

.preview__scroll {
  position: absolute;
  bottom: 40px;
  left: 50%;
  background-size: cover;
  transform: translateX(-50%); }
  .preview__scroll--mouse {
    width: 24px;
    height: 63px;
    background-image: url("../img/icons/scroll-mouse.svg"); }
  .preview__scroll--touch {
    background-image: url("../img/icons/scroll-touch.svg"); }
  @media (max-width: 1023px) {
    .preview__scroll {
      bottom: 24px; } }
  @media (min-width: 480px) {
    .preview__scroll--touch {
      width: 42px;
      height: 70px; } }
  @media (max-width: 479px) {
    .preview__scroll {
      bottom: 40px; }
      .preview__scroll--touch {
        width: 32px;
        height: 48px; } }

@media (min-width: 1024px) {
  .project {
    padding: 152px 0 80px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .project {
    padding: 245px 0 70px; } }

@media (max-width: 767px) {
  .project {
    padding: 128px 0 45px; } }

.project__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%; }
  @media (max-width: 1439px) {
    .project__wrapper {
      margin: 0 auto; } }
  @media (min-width: 1024px) {
    .project__wrapper {
      max-width: 960px; } }

@media (min-width: 1024px) {
  .project__title {
    margin: 0 0 64px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .project__title {
    max-width: 640px;
    margin: 0 auto 40px; }
    .project__title .title__tag {
      display: none; } }

@media (max-width: 767px) {
  .project__title {
    max-width: 480px;
    margin: 0 auto 24px; } }

@media (max-width: 479px) {
  .project__title {
    max-width: 360px; }
    .project__title .title__text {
      line-height: 1.05; } }

.project__swiper {
  align-self: end;
  width: 100%; }
  @media (min-width: 1024px) {
    .project__swiper {
      max-width: 880px;
      margin: 0 0 64px; } }
  @media (min-width: 941px) {
    .project__swiper {
      overflow: hidden; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .project__swiper {
      max-width: 640px;
      margin: 0 auto 40px; } }
  @media (max-width: 767px) {
    .project__swiper {
      max-width: calc(100% - 24px);
      margin: 0 auto 24px;
      padding: 0 24px 0 24px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .project__swiper {
      max-width: 480px; } }
  @media (max-width: 479px) {
    .project__swiper {
      max-width: 360px; } }

.project__short-description {
  display: grid;
  grid-row-gap: 8px;
  align-self: end; }
  .project__short-description p {
    margin: 0;
    color: #fff;
    line-height: 160%; }
  @media (min-width: 1024px) {
    .project__short-description {
      max-width: 880px;
      margin: 0 0 64px;
      padding: 0 80px 0 0; }
      .project__short-description p {
        font-size: 18px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .project__short-description {
      max-width: 640px;
      margin: 0 auto 40px; }
      .project__short-description p {
        font-size: 16px; } }
  @media (max-width: 767px) {
    .project__short-description {
      margin: 0 auto 24px; }
      .project__short-description p {
        font-size: 12px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .project__short-description {
      max-width: 480px; } }
  @media (max-width: 479px) {
    .project__short-description {
      max-width: 360px; } }

.project__works {
  grid-template-rows: max-content max-content;
  width: 100%; }
  @media (min-width: 1024px) {
    .project__works {
      margin: 0 0 80px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .project__works {
      max-width: 740px;
      margin: 0 0 60px; } }
  @media (max-width: 767px) {
    .project__works {
      max-width: 580px;
      margin: 0 0 40px; } }
  @media (min-width: 480px) and (max-width: 1023px) {
    .project__works {
      width: calc(100vw - 48px);
      margin-right: auto;
      margin-left: auto; } }
  @media (max-width: 479px) {
    .project__works {
      width: 100vw;
      margin-right: -16px;
      margin-left: -16px; }
      .project__works .slider__dots {
        padding: 19px 0 0; } }
  @media (max-width: 319px) {
    .project__works {
      min-width: 100%; } }

.project__works-container {
  align-items: start; }
  @media (min-width: 1024px) {
    .project__works-container {
      width: 100%;
      max-width: 800px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .project__works-container {
      width: calc(100vw - 128px);
      max-width: 640px; } }
  @media (max-width: 767px) {
    .project__works-container {
      width: calc(100vw - 80px);
      max-width: 480px; } }
  @media (max-width: 479px) {
    .project__works-container {
      width: calc(100vw - 56px); } }
  @media (max-width: 319px) {
    .project__works-container {
      min-width: 100%; } }

.project__slider {
  align-items: start; }

.project__slide {
  display: flex;
  align-items: start;
  padding: 8px;
  border: 1px solid;
  border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.4) 49.92%, rgba(255, 255, 255, 0.12) 100%);
  border-image-slice: 1; }

@media (max-width: 479px) {
  .project__works-buttons {
    display: none; } }

.info-project {
  width: 100%; }
  @media (max-width: 1439px) {
    .info-project {
      margin: 0 auto; } }
  @media (min-width: 1024px) {
    .info-project {
      padding: 80px 0 0; }
      .info-project .item-step:not(:last-child) .item-step__icon::before {
        right: -170px;
        width: 170px;
        background-image: url("../img/icons/step-line-big.svg"); } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .info-project {
      max-width: 640px;
      padding: 64px 0 0; } }
  @media (max-width: 767px) {
    .info-project {
      max-width: 480px;
      padding: 40px 0 0; } }
  @media (max-width: 479px) {
    .info-project {
      max-width: 360px; } }

@media (min-width: 1024px) {
  .info-project__swiper {
    margin: 0 0 24px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .info-project__swiper {
    margin: 0 0 64px; } }

@media (max-width: 767px) {
  .info-project__swiper {
    margin: 0 0 24px; } }

.info-project__slider-container {
  max-width: 960px; }

.info-project__steps {
  margin: 0 0 64px; }

.info-project__numbers {
  counter-reset: li; }

.info-project__number {
  counter-increment: li; }
  .info-project__number::before {
    content: counter(li);
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    align-items: center;
    justify-content: center;
    color: var(--accent-color);
    font-weight: 300;
    font-size: 40px;
    line-height: 52px;
    text-align: center; }
  @media (min-width: 1024px) {
    .info-project__number {
      width: 210px; } }
  @media (max-width: 767px) {
    .info-project__number::before {
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .info-project__number {
      width: 200px; } }
  @media (min-width: 768px) {
    .info-project__number::before {
      width: 80px;
      height: 80px; } }
  @media (max-width: 767px) {
    .info-project__number {
      width: 168px; }
      .info-project__number::before {
        width: 72px;
        height: 72px; } }

.info-project__list {
  display: grid;
  max-width: 880px;
  padding: 0;
  list-style: none; }
  @media (max-width: 1439px) {
    .info-project__list {
      margin: 0 auto; } }
  @media (min-width: 1024px) {
    .info-project__list {
      grid-row-gap: 40px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .info-project__list {
      grid-row-gap: 32px; } }
  @media (max-width: 767px) {
    .info-project__list {
      grid-row-gap: 20px; } }

@media (min-width: 1024px) {
  .info-project__item .tag-small__content p {
    line-height: 162%; } }

@media (min-width: 1024px) {
  .projects {
    padding: 152px 0 80px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .projects {
    padding: 245px 0 80px; } }

@media (max-width: 767px) {
  .projects {
    padding: 124px 0 24px; } }

.projects__container {
  display: grid; }

.projects__wrapper {
  display: grid;
  width: 100%; }
  @media (max-width: 1439px) {
    .projects__wrapper {
      justify-self: center; } }
  @media (min-width: 1024px) {
    .projects__wrapper {
      max-width: 960px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .projects__wrapper {
      max-width: 640px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .projects__wrapper {
      max-width: 480px; } }
  @media (max-width: 479px) {
    .projects__wrapper {
      max-width: 360px; } }

@media (min-width: 1024px) {
  .projects__title {
    margin: 0 0 64px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .projects__title {
    margin: 0 0 40px; } }

@media (max-width: 767px) {
  .projects__title {
    margin: 0 0 20px; } }

.projects__list {
  display: grid;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  list-style: none; }
  @media (min-width: 1440px) {
    .projects__list {
      justify-self: end; } }
  @media (max-width: 1439px) {
    .projects__list {
      justify-self: center; } }
  @media (min-width: 1024px) {
    .projects__list {
      grid-template-columns: minmax(280px, 400px) minmax(280px, 400px);
      grid-gap: 64px 20px;
      max-width: 880px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .projects__list {
      grid-template-columns: minmax(280px, 304px) minmax(280px, 304px);
      grid-gap: 32px; } }
  @media (max-width: 767px) {
    .projects__list {
      grid-template-columns: 1fr; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .projects__list {
      grid-gap: 16px; } }
  @media (max-width: 479px) {
    .projects__list {
      grid-row-gap: 16px; } }

@media (min-width: 1024px) {
  .projects-index {
    padding: 80px 0; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .projects-index {
    padding: 60px 0; } }

@media (max-width: 767px) {
  .projects-index {
    padding: 40px 0; } }

.projects-index__container {
  display: grid; }

.projects-index__wrapper {
  display: grid;
  width: 100%; }
  @media (max-width: 1439px) {
    .projects-index__wrapper {
      justify-self: center; } }
  @media (min-width: 1024px) {
    .projects-index__wrapper {
      max-width: 960px; } }
  @media (min-width: 480px) and (max-width: 1023px) {
    .projects-index__wrapper {
      max-width: calc(100vw - 48px); } }
  @media (max-width: 479px) {
    .projects-index__wrapper {
      max-width: calc(100vw - 32px); } }

.projects-index__title {
  position: relative;
  width: 100%; }
  @media (min-width: 1024px) {
    .projects-index__title {
      margin: 0 0 64px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .projects-index__title {
      max-width: 640px;
      margin: 0 auto 40px; } }
  @media (max-width: 767px) {
    .projects-index__title {
      max-width: 480px;
      margin: 0 auto 24px; } }
  @media (max-width: 479px) {
    .projects-index__title {
      max-width: 360px; } }

@media (min-width: 1024px) {
  .projects-index__more {
    top: 31px; } }

@media (min-width: 480px) and (max-width: 1023px) {
  .projects-index__more {
    top: 8px; } }

@media (min-width: 480px) {
  .projects-index__more {
    position: absolute;
    right: 0; } }

@media (max-width: 479px) {
  .projects-index__more {
    display: none; } }

@media (min-width: 1024px) {
  .projects-index__swiper {
    max-width: 960px; } }

@media (min-width: 480px) and (max-width: 1023px) {
  .projects-index__swiper {
    max-width: calc(100vw - 48px); } }

@media (max-width: 479px) {
  .projects-index__swiper {
    max-width: calc(100vw - 32px); } }

.projects-index__slider-container {
  margin: 0 auto; }
  @media (min-width: 1024px) {
    .projects-index__slider-container {
      max-width: 776px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .projects-index__slider-container {
      max-width: 560px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .projects-index__slider-container {
      max-width: 480px;
      width: calc(100vw - 40px); } }
  @media (max-width: 479px) {
    .projects-index__slider-container {
      max-width: 360px;
      width: calc(100vw - 32px); } }

@media (min-width: 1024px) {
  .projects-index__slide {
    width: 376px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .projects-index__slide {
    width: 304px; } }

@media (max-width: 767px) {
  .projects-index__slide {
    width: 240px; } }

@media (min-width: 480px) {
  .projects-index__mobile-more {
    display: none; } }

@media (max-width: 479px) {
  .projects-index__mobile-more {
    margin: 40px 0 0;
    min-width: 104px;
    justify-self: center; } }

@media (min-width: 1024px) {
  .quotes {
    padding: 80px 0; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .quotes {
    padding: 94px 0 56px; } }

@media (max-width: 767px) {
  .quotes {
    padding: 40px 0 52px; } }

.quotes__container {
  display: grid; }
  @media (max-width: 479px) {
    .quotes__container {
      padding: 0; } }

.quotes__wrapper {
  position: relative;
  display: grid;
  width: 100%; }
  .quotes__wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 34.9%, rgba(255, 255, 255, 0) 100%); }
  @media (max-width: 1439px) {
    .quotes__wrapper {
      justify-self: center; } }
  @media (min-width: 1024px) {
    .quotes__wrapper {
      max-width: 960px;
      padding: 64px 0 0; }
      .quotes__wrapper::after {
        top: 64px;
        left: 0;
        width: 36px;
        height: 24px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .quotes__wrapper {
      padding: 40px 0 0; } }
  @media (min-width: 480px) and (max-width: 1023px) {
    .quotes__wrapper {
      max-width: calc(100vw - 48px); }
      .quotes__wrapper::after {
        top: 40px;
        left: 40px;
        width: 24px;
        height: 16px; } }
  @media (max-width: 767px) {
    .quotes__wrapper {
      padding: 24px 0 0; } }
  @media (max-width: 479px) {
    .quotes__wrapper {
      max-width: calc(100vw);
      padding: 24px 16px 0; }
      .quotes__wrapper::after {
        top: 24px;
        left: 16px;
        width: 12px;
        height: 8px; } }

@media (min-width: 1024px) {
  .quotes__slider-container {
    max-width: 800px; } }

@media (min-width: 480px) and (max-width: 1023px) {
  .quotes__slider-container {
    max-width: calc(100vw - 208px); } }

@media (max-width: 479px) {
  .quotes__slider-container {
    max-width: calc(100vw - 32px);
    padding: 0 0 0 24px; } }

.quotes__icon {
  position: absolute;
  display: flex;
  color: var(--accent-color); }
  @media (min-width: 1024px) {
    .quotes__icon {
      top: 0;
      left: 0; }
      .quotes__icon svg {
        width: 36px;
        height: 24px; } }
  @media (min-width: 480px) and (max-width: 1023px) {
    .quotes__icon {
      top: 0;
      left: 40px; }
      .quotes__icon svg {
        width: 24px;
        height: 16px; } }
  @media (max-width: 479px) {
    .quotes__icon {
      top: 0px;
      left: 0px; }
      .quotes__icon svg {
        width: 12px;
        height: 8px; } }

@media (min-width: 1024px) {
  .services {
    padding: 80px 0; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .services {
    padding: 60px 0; } }

@media (max-width: 767px) {
  .services {
    padding: 40px 0; } }

.services__container {
  display: grid; }

.services__wrapper {
  display: grid;
  width: 100%; }
  @media (max-width: 1439px) {
    .services__wrapper {
      justify-self: center; } }
  @media (min-width: 1024px) {
    .services__wrapper {
      max-width: 960px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .services__wrapper {
      max-width: 640px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .services__wrapper {
      max-width: 480px; } }
  @media (max-width: 479px) {
    .services__wrapper {
      max-width: 360px; } }

@media (min-width: 1024px) {
  .services__title {
    margin: 0 0 64px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .services__title {
    margin: 0 0 40px; } }

@media (max-width: 767px) {
  .services__title {
    margin: 0 0 24px; } }

.services__list {
  display: grid;
  padding: 0;
  list-style: none; }
  @media (min-width: 1024px) {
    .services__list {
      grid-row-gap: 40px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .services__list {
      grid-row-gap: 32px; } }
  @media (max-width: 767px) {
    .services__list {
      grid-row-gap: 24px; } }

@media (min-width: 1024px) {
  .stack {
    padding: 80px 0; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .stack {
    padding: 60px 0; } }

@media (max-width: 767px) {
  .stack {
    padding: 40px 0; } }

.stack__container {
  display: grid; }

.stack__wrapper {
  display: grid;
  width: 100%; }
  @media (max-width: 1439px) {
    .stack__wrapper {
      justify-self: center; } }
  @media (min-width: 1024px) {
    .stack__wrapper {
      max-width: 960px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .stack__wrapper {
      max-width: 640px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .stack__wrapper {
      max-width: 480px; } }
  @media (max-width: 479px) {
    .stack__wrapper {
      max-width: 360px; } }

@media (min-width: 1024px) {
  .stack__title {
    margin: 0 0 64px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .stack__title {
    margin: 0 0 40px; } }

@media (max-width: 767px) {
  .stack__title {
    margin: 0 0 24px; } }

.stack__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-self: center;
  max-width: 980px;
  padding: 0;
  list-style: none; }
  @media (min-width: 1024px) {
    .stack__list {
      width: calc(100% + 20px);
      margin: -10px 0;
      padding: 0 0 0 80px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .stack__list {
      width: calc(100% + 16px);
      margin: -8px 0; } }
  @media (max-width: 767px) {
    .stack__list {
      width: calc(100% + 12px);
      margin: -6px 0; } }

@media (min-width: 1024px) {
  .steps {
    padding: 80px 0; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .steps {
    padding: 60px 0; } }

@media (max-width: 767px) {
  .steps {
    padding: 40px 0; } }

.steps__container {
  display: grid; }

.steps__wrapper {
  display: grid;
  width: 100%; }
  @media (max-width: 1439px) {
    .steps__wrapper {
      justify-self: center; } }
  @media (min-width: 1024px) {
    .steps__wrapper {
      max-width: 960px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .steps__wrapper {
      max-width: 640px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .steps__wrapper {
      max-width: 480px; } }
  @media (max-width: 479px) {
    .steps__wrapper {
      max-width: 360px; } }

@media (min-width: 1024px) {
  .steps__title {
    margin: 0 0 64px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .steps__title {
    margin: 0 0 40px; } }

@media (max-width: 767px) {
  .steps__title {
    margin: 0 0 24px; } }

@media (min-width: 1024px) {
  .steps__swiper {
    max-width: 960px;
    padding: 0 0 0 80px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .steps__swiper {
    max-width: 640px; } }

@media (min-width: 480px) and (max-width: 767px) {
  .steps__swiper {
    max-width: 480px; } }

@media (max-width: 479px) {
  .steps__swiper {
    max-width: 360px; } }

@media (min-width: 1024px) {
  .steps__slider-container {
    max-width: 960px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .steps__slider-container {
    width: calc(100vw - 48px); } }

@media (max-width: 767px) {
  .steps__slider-container {
    width: calc(100vw - 48px); } }

@media (max-width: 479px) {
  .steps__slider-container {
    width: calc(100vw - 32px); } }

@media (min-width: 1024px) {
  .steps__item {
    width: 240px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .steps__item {
    width: 200px; } }

@media (max-width: 767px) {
  .steps__item {
    width: 168px; } }

@media (min-width: 1024px) {
  .team {
    padding: 152px 0 80px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .team {
    padding: 245px 0 59px; } }

@media (max-width: 767px) {
  .team {
    padding: 124px 0 39px; } }

.team__container {
  display: grid; }

.team__wrapper {
  display: grid;
  width: 100%; }
  @media (max-width: 1439px) {
    .team__wrapper {
      justify-self: center; } }
  @media (min-width: 1024px) {
    .team__wrapper {
      max-width: 960px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .team__wrapper {
      max-width: 640px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .team__wrapper {
      max-width: 480px; } }
  @media (max-width: 479px) {
    .team__wrapper {
      max-width: 360px; } }

@media (min-width: 1024px) {
  .team__title {
    margin: 0 0 70px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .team__title {
    margin: 0 0 46px; } }

@media (max-width: 767px) {
  .team__title {
    margin: 0 0 27px;
    padding-bottom: 21px; } }

.team__list {
  display: grid;
  justify-content: space-between;
  padding: 0;
  list-style: none; }
  @media (max-width: 1439px) {
    .team__list {
      justify-self: center;
      width: 100%; } }
  @media (min-width: 1024px) {
    .team__list {
      grid-template-columns: repeat(3, 240px);
      grid-gap: 69px 20px;
      max-width: 880px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .team__list {
      grid-template-columns: repeat(3, 186px);
      grid-gap: 46px 20px; } }
  @media (max-width: 767px) {
    .team__list {
      text-align: center; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .team__list {
      grid-template-columns: repeat(3, 136px);
      grid-gap: 24px 10px; } }
  @media (max-width: 479px) {
    .team__list {
      grid-template-columns: repeat(2, 136px);
      grid-gap: 28px 16px;
      justify-content: center; } }

.item-team {
  display: grid; }
  .item-team:hover .item-team__image-wrap, .item-team:focus-within .item-team__image-wrap {
    border: 1px solid var(--accent-color);
    box-shadow: 0 0 16px var(--accent-color); }

.item-team__name {
  order: 2;
  margin: 0;
  color: #fff;
  font-weight: 700;
  line-height: 160%; }
  @media (min-width: 1024px) {
    .item-team__name {
      font-size: 18px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .item-team__name {
      font-size: 16px; } }
  @media (max-width: 767px) {
    .item-team__name {
      font-size: 12px; } }

.item-team__position {
  order: 3;
  color: var(--accent-color);
  line-height: 160%;
  transition: 0.2s; }
  @media (min-width: 1024px) {
    .item-team__position {
      margin: -3px 0 22px;
      font-size: 14px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .item-team__position {
      margin: -3px 0 19px;
      font-size: 12px; } }
  @media (max-width: 767px) {
    .item-team__position {
      margin: 0 0 8px;
      font-size: 10px; } }

.item-team__description {
  order: 4;
  color: #fff;
  line-height: 160%; }
  @media (min-width: 1024px) {
    .item-team__description {
      font-size: 14px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .item-team__description {
      font-size: 12px; } }
  @media (max-width: 767px) {
    .item-team__description {
      font-size: 10px; } }

.item-team__image-wrap {
  position: relative;
  display: flex;
  order: 1;
  border: 1px solid;
  border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.08) 0%, #fff 49.92%, rgba(255, 255, 255, 0.08) 100%);
  border-image-slice: 1;
  transition: box-shadow 0.2s; }
  @media (min-width: 1024px) {
    .item-team__image-wrap {
      width: 208px;
      height: 208px;
      margin: 26px 0 16px 30px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .item-team__image-wrap {
      width: 164px;
      height: 164px;
      margin: 16px 0 17px 22px; } }
  @media (max-width: 767px) {
    .item-team__image-wrap {
      width: 124px;
      height: 124px;
      margin: 8px 0 8px 12px; } }

.item-team__image {
  position: absolute;
  z-index: 1;
  object-fit: cover;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px); }
  @media (min-width: 1024px) {
    .item-team__image {
      top: -24px;
      left: -24px;
      width: calc(100% + 2px);
      max-width: calc(100% + 2px);
      height: calc(100% + 2px);
      max-height: calc(100% + 2px); } }
  @media (max-width: 1023px) {
    .item-team__image {
      width: calc(100% - 2px);
      max-width: calc(100% - 2px);
      height: calc(100% - 2px);
      max-height: calc(100% - 2px); } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .item-team__image {
      top: -16px;
      left: -16px; } }
  @media (max-width: 767px) {
    .item-team__image {
      top: -8px;
      left: -8px; } }

.menu-radial {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: none;
  width: 766px;
  height: 766px;
  margin: auto 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  pointer-events: none;
  user-select: none;
  transform: translate(48.5%, 0); }
  @media (min-width: 1440px) {
    .menu-radial {
      display: block; } }
  @media (max-width: 1800px) {
    .menu-radial {
      transform: translate(calc(48% + calc((1800px - 100vw) / (1800 - 1440) * (766 * 0.22))), 0); } }
  .menu-radial ul {
    list-style: none; }
  .menu-radial a {
    pointer-events: visible; }
    @media (hover: hover) {
      .menu-radial a {
        will-change: filter;
        transition: var(--transition-main);
        transition-property: color, filter; }
        .menu-radial a:hover {
          color: var(--accent-color);
          filter: drop-shadow(0 0 12px var(--accent-color)); } }
  .menu-radial__circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .menu-radial__inner {
    position: absolute;
    top: 50%;
    right: calc(100% - 300px);
    z-index: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 600px;
    height: 540px;
    pointer-events: none;
    transform: translateY(-50%);
    -webkit-mask-image: linear-gradient(0deg, transparent, black 40px calc(100% - 40px), transparent);
    mask-image: linear-gradient(0deg, transparent, black 40px calc(100% - 40px), transparent); }
    @media (max-height: 700px) {
      .menu-radial__inner {
        height: 360px; } }
    @media (max-height: 550px) {
      .menu-radial__inner {
        height: 60vh; } }
  .menu-radial__list {
    position: relative;
    padding: 0;
    color: #fff; }
    .menu-radial__list li {
      margin-bottom: 16px; }
      .menu-radial__list li:last-child {
        margin-bottom: 0; }
  .menu-radial__sublist {
    display: none;
    max-width: 280px;
    margin-top: 16px;
    padding-left: 150px;
    text-align: left; }
  .menu-radial__link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase; }
    @media (hover: hover) {
      .menu-radial__link:hover .icon {
        border-color: var(--accent-color);
        background: #fff; } }
    .menu-radial__link .icon {
      position: relative;
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin-left: 16px;
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: #1b1f3a;
      transition: var(--transition-main);
      transition-property: border-color, background-color; }
      .menu-radial__link .icon::after, .menu-radial__link .icon::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: conic-gradient(from 0.33turn at 50% 50%, #fff, var(--accent-color));
        opacity: 0;
        transform: translate(-50%, -50%); }
      .menu-radial__link .icon::after {
        width: 52px;
        height: 52px;
        background: #fff; }
      .menu-radial__link .icon svg {
        z-index: 1; }
    .menu-radial__link .icon-pointer {
      display: none;
      align-self: flex-start;
      margin-right: -32px;
      margin-left: 8px;
      fill: #fff;
      transform: translateY(-10px);
      fill-rule: evenodd;
      clip-rule: evenodd; }
      @media (max-width: 1700px) {
        .menu-radial__link .icon-pointer {
          width: 100px;
          transform: translateY(-16px); } }
    .menu-radial__link.is-active {
      margin-top: 18px; }
      .menu-radial__link.is-active + .menu-radial__sublist {
        display: block; }
      .menu-radial__link.is-active > span:first-child {
        align-self: flex-start;
        transform: translateY(-18px); }
      .menu-radial__link.is-active .icon-pointer {
        display: block; }
      .menu-radial__link.is-active .icon {
        width: 64px;
        height: 64px;
        margin-left: 0;
        background: #fff;
        color: var(--accent-color);
        box-shadow: 0 0 24px var(--accent-color); }
        .menu-radial__link.is-active .icon::before {
          animation: loopIconBorder 8s linear infinite; }
        .menu-radial__link.is-active .icon::after, .menu-radial__link.is-active .icon::before {
          opacity: 1; }
  .menu-radial__sublink {
    position: relative;
    display: inline-flex;
    max-width: 128px;
    text-transform: capitalize; }
    .menu-radial__sublink .dot {
      position: absolute;
      top: 50%;
      left: 100%;
      display: flex;
      align-items: center;
      transform: translateY(-50%); }
      .menu-radial__sublink .dot::before {
        content: '';
        display: none;
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        margin-left: 8px;
        border: 1px solid #fff;
        border-radius: 50%; }
      .menu-radial__sublink .dot::after {
        content: '';
        display: inline-flex;
        flex: 1;
        width: 0;
        height: 1px;
        background-image: linear-gradient(to right, transparent, var(--accent-color)); }
      .menu-radial__sublink .dot i {
        display: inline-flex;
        flex-shrink: 0;
        order: 1;
        width: 16px;
        height: 16px;
        border: 1px solid var(--accent-color);
        border-radius: 50%;
        background-color: #1b1f3a;
        font-size: 0;
        line-height: 1; }
    .menu-radial__sublink.is-active .dot::before {
      display: inline-flex; }
    .menu-radial__sublink.is-active .dot::after {
      background-color: #fff;
      background-image: none; }
    .menu-radial__sublink.is-active .dot i {
      border-width: 4px;
      border-color: #fff;
      background-color: var(--accent-color); }

#drag-area {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: grab;
  pointer-events: visible; }
  #drag-area img {
    display: none;
    opacity: 0.7;
    pointer-events: none;
    transform: translateX(200px); }
    @media (max-width: 1800px) {
      #drag-area img {
        transform: translateX(calc(200px + calc((1800px - 100vw) / (1800 - 1440) * -110))); } }
  #drag-area.info-visible img {
    display: block; }

@keyframes loopIconBorder {
  to {
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes preloaderSphere {
  to {
    opacity: 0.6; } }

.preloader {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110%;
  background-image: linear-gradient(to top, #231647 90%, transparent 100%);
  transition: transform 0.6s ease-in-out; }
  .preloader__paths {
    animation: changeColors 2s linear infinite; }
  .preloader.hidden {
    pointer-events: none;
    transform: translateY(110%); }
    .preloader.hidden .preloader__paths {
      animation: none; }

.body-bg {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../img/bg-575.jpg");
  background-size: cover;
  background-repeat: no-repeat; }
  @media (min-width: 576px) {
    .body-bg {
      background-image: url("../img/bg-1439.jpg"); } }
  @media (min-width: 1440px) {
    .body-bg {
      background-image: url("../img/bg-1440.jpg"); } }
  .body-bg::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(35, 22, 71, 0.2); }
  .body-bg__img {
    object-fit: cover;
    width: 100%;
    height: 100%; }

#webgl {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%; }

@keyframes changeColors {
  25% {
    fill: var(--color-purple); }
  50% {
    fill: var(--color-yellow); }
  75% {
    fill: var(--color-pink); }
  100% {
    fill: var(--color-green); } }

/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */
