// STEP
.steps {
  @media (min-width: 1024px) {
    padding: 80px 0;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }
}

.steps__container {
  display: grid;
}

.steps__wrapper {
  display: grid;
  width: 100%;

  @media (max-width: 1439px) {
    justify-self: center;
  }

  @media (min-width: 1024px) {
    max-width: 960px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.steps__title {
  @media (min-width: 1024px) {
    margin: 0 0 64px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 0 0 40px;
  }

  @media (max-width: 767px) {
    margin: 0 0 24px;
  }
}

.steps__swiper {
  @media (min-width: 1024px) {
    max-width: 960px;
    padding: 0 0 0 80px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.steps__slider-container {
  @media (min-width: 1024px) {
    max-width: 960px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: calc(100vw - 48px);
  }

  @media (max-width: 767px) {
    width: calc(100vw - 48px);
  }

  @media (max-width: 479px) {
    width: calc(100vw - 32px);
  }
}

.steps__item {
  @media (min-width: 1024px) {
    width: 240px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 200px;
  }

  @media (max-width: 767px) {
    width: 168px;
  }
}
