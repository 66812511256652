// CARD STACK
.card-stack {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.08) 0%, #fff 49.92%, rgba(255, 255, 255, 0.08) 100%);
  border-image-slice: 1;
  transition: box-shadow 0.2s;

  &:hover,
  &:focus {
    border: 1px solid var(--accent-color);
    box-shadow: 0 0 16px var(--accent-color);
  }

  @media (min-width: 1024px) {
    width: 160px;
    height: 160px;

    &--margin {
      margin: 12px 10px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 148px;
    height: 148px;

    &--margin {
      margin: 8px;
    }
  }

  @media (max-width: 767px) {
    width: 88px;
    height: 88px;

    &--margin {
      margin: 6px;
    }
  }
}

.card-stack__image {
  @media (min-width: 768px) {
    transform: scale(1);
  }

  @media (min-width: 480px) and (max-width: 767px) {
    transform: scale(0.87);
  }

  @media (max-width: 479px) {
    transform: scale(0.55);
  }
}
