// QUOTES
.quotes {
  @media (min-width: 1024px) {
    padding: 80px 0;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 94px 0 56px;
  }

  @media (max-width: 767px) {
    padding: 40px 0 52px;
  }
}

.quotes__container {
  display: grid;

  @media (max-width: 479px) {
    padding: 0;
  }
}

.quotes__wrapper {
  position: relative;
  display: grid;
  // overflow: hidden;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 34.9%, rgba(255, 255, 255, 0) 100%);
  }

  @media (max-width: 1439px) {
    justify-self: center;
  }

  @media (min-width: 1024px) {
    max-width: 960px;
    padding: 64px 0 0;

    &::after {
      top: 64px;
      left: 0;
      width: 36px;
      height: 24px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 40px 0 0;
  }

  @media (min-width: 480px) and (max-width: 1023px) {
    max-width: calc(100vw - 48px);

    &::after {
      top: 40px;
      left: 40px;
      width: 24px;
      height: 16px;
    }
  }

  @media (max-width: 767px) {
    padding: 24px 0 0;
  }

  @media (max-width: 479px) {
    max-width: calc(100vw);
    padding: 24px 16px 0;

    &::after {
      top: 24px;
      left: 16px;
      width: 12px;
      height: 8px;
    }
  }
}

.quotes__slider-container {
  @media (min-width: 1024px) {
    max-width: 800px;
  }

  @media (min-width: 480px) and (max-width: 1023px) {
    max-width: calc(100vw - 208px);
  }

  @media (max-width: 479px) {
    max-width: calc(100vw - 32px);
    padding: 0 0 0 24px;
  }
}

.quotes__icon {
  position: absolute;
  display: flex;
  color: var(--accent-color);

  @media (min-width: 1024px) {
      top: 0;
      left: 0;

      svg {
        width: 36px;
        height: 24px;
      }
  }

  @media (min-width: 480px) and (max-width: 1023px) {
      top: 0;
      left: 40px;

      svg {
        width: 24px;
        height: 16px;
      }
  }

  @media (max-width: 479px) {
      top: 0px;
      left: 0px;

      svg {
        width: 12px;
        height: 8px;
      }
    }
}
