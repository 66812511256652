// TAG SMALL
.tag-small {
  position: relative;
  display: grid;

  @media (min-width: 1024px) {
    padding-left: 80px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding-left: 40px;
  }

  @media (max-width: 767px) {
    padding-left: 24px;
  }
}

.tag-small__title {
  margin: 0 0 8px;
  color: #fff;
  font-weight: 700;

  @media (min-width: 1024px) {
    font-size: 40px;
    line-height: 52px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.tag-small__content {
  display: grid;
  grid-row-gap: 8px;

  p {
    margin: 0;
    color: #fff;
    line-height: 160%;
  }

  @media (min-width: 1024px) {
    p {
      font-size: 18px;
      line-height: 175%;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    p {
      font-size: 16px;
    }
  }

  @media (max-width: 767px) {
    p {
      font-size: 12px;
    }
  }
}

.tag-small__decor {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  width: 24px;
  height: 24px;
  color: transparent;
  stroke: var(--accent-color);

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  @media (max-width: 767px) {
    width: 8px;
    height: 8px;

    svg {
      width: 8px;
      height: 8px;
    }
  }
}
