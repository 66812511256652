:root {
  --color-green: #0fb56f;
  --color-purple: #835bf3;
  --color-yellow: #ffbf1c;
  --color-pink: #db488f;
  --accent-color: #0fb56f;
  --error-color: #eb5757;
  --btn-clip-w: 13px;
  --btn-triangle-w: 8px;
  --transition-main: .3s ease-out;

  &[data-theme='green'] {
    --accent-color: var(--color-green);
  }

  &[data-theme='purple'] {
    --accent-color: var(--color-purple);
  }

  &[data-theme='yellow'] {
    --accent-color: var(--color-yellow);
  }

  &[data-theme='pink'] {
    --accent-color: var(--color-pink);
  }
}

$mobile-sm:     479px;
$mobile:        575px;
$mobile-big:    767px;
$tablet:        991px;
$desktop:       1199px;

$grid-spacing-b: 30px;
$grid-spacing-m: 24px;
$grid-spacing-s: 16px;

$base-img: '../img/';

$font-size: 18px;
$line-height: 160%;
// Fonts
$default-font: 'IBM sans', sans-serif;

// Colors
$text-color: #fff;
$color-error: #eb5757;
$background-color: #231647;
