// QUOTE
.quote {
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto auto;
  height: 100%;

  @media (min-width: 1024px) {
    padding: 24px 32px;
  }

  @media (max-width: 1023px) {
    padding: 24px;
  }
}

.quote__content {
  position: relative;
  display: grid;
  grid-row-gap: 8px;
  margin: 0 0 16px;

  p {
    margin: 0;
    color: #fff;
    line-height: 160%;
  }

  @media (min-width: 1024px) {
    p {
      font-size: 18px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    p {
      font-size: 16px;
    }
  }

  @media (max-width: 767px) {
    p {
      font-size: 12px;
    }
  }
}

.quote__author {
  position: relative;
  display: grid;
}

.quote__name {
  margin: 0 0 8px;
  color: #fff;
  font-weight: 500;
  font-style: italic;
  line-height: 160%;
  text-align: right;

  @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 767px) {
    margin: 0;
    font-size: 12px;
  }
}

.quote__position {
  color: #fff;
  font-style: italic;
  font-size: 12px;
  line-height: 160%;
  text-align: right;

  @media (max-width: 767px) {
    font-size: 10px;
  }
}
