// SUB TITLE
.sub-title {
  color: #fff;
  font-weight: 700;

  @media (min-width: 1024px) {
    font-size: 40px;
    line-height: 52px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;
  }
}
