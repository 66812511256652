.link {

  @media (hover: hover) {
    will-change: filter;
    transition: var(--transition-main);
    transition-property: filter, color, opacity;

    &:hover,
    &:focus {
      color: var(--accent-color);
      filter: drop-shadow(0 0 12px var(--accent-color));
    }
  }
}
