// PROJECTS
.projects {
  @media (min-width: 1024px) {
    padding: 152px 0 80px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 245px 0 80px;
  }

  @media (max-width: 767px) {
    padding: 124px 0 24px;
  }
}

.projects__container {
  display: grid;
}

.projects__wrapper {
  display: grid;
  width: 100%;

  @media (max-width: 1439px) {
    justify-self: center;
  }

  @media (min-width: 1024px) {
    max-width: 960px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.projects__title {
  @media (min-width: 1024px) {
    margin: 0 0 64px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 0 0 40px;
  }

  @media (max-width: 767px) {
    margin: 0 0 20px;
  }
}

.projects__list {
  display: grid;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  list-style: none;

  @media (min-width: 1440px) {
    justify-self: end;
  }

  @media (max-width: 1439px) {
    justify-self: center;
  }

  @media (min-width: 1024px) {
    grid-template-columns: minmax(280px, 400px) minmax(280px, 400px);
    grid-gap: 64px 20px;
    max-width: 880px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: minmax(280px, 304px) minmax(280px, 304px);
    grid-gap: 32px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    grid-gap: 16px;
  }

  @media (max-width: 479px) {
    grid-row-gap: 16px;
  }
}
