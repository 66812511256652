// PROJECTS INDEX
.projects-index {
  @media (min-width: 1024px) {
    padding: 80px 0;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }
}

.projects-index__container {
  display: grid;
}

.projects-index__wrapper {
  display: grid;
  width: 100%;

  @media (max-width: 1439px) {
    justify-self: center;
  }

  @media (min-width: 1024px) {
    max-width: 960px;
  }

  @media (min-width: 480px) and (max-width: 1023px) {
    max-width: calc(100vw - 48px);
  }

  @media (max-width: 479px) {
    max-width: calc(100vw - 32px);
  }
}

.projects-index__title {
  position: relative;
  width: 100%;

  @media (min-width: 1024px) {
    margin: 0 0 64px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
    margin: 0 auto 40px;
  }

  @media (max-width: 767px) {
    max-width: 480px;
    margin: 0 auto 24px;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.projects-index__more {
  @media (min-width: 1024px) {
    top: 31px;
  }

  @media (min-width: 480px) and (max-width: 1023px) {
    top: 8px;
  }

  @media (min-width: 480px) {
    position: absolute;
    right: 0;
  }

  @media (max-width: 479px) {
    display: none;
  }
}

.projects-index__swiper {
  @media (min-width: 1024px) {
    max-width: 960px;
  }

  @media (min-width: 480px) and (max-width: 1023px) {
    max-width: calc(100vw - 48px);
  }

  @media (max-width: 479px) {
    max-width: calc(100vw - 32px);
  }
}

.projects-index__slider-container {
  margin: 0 auto;

  @media (min-width: 1024px) {
    max-width: 776px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 560px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;
    width: calc(100vw - 40px);
  }

  @media (max-width: 479px) {
    max-width: 360px;
    width: calc(100vw - 32px);
  }
}

.projects-index__slide {
  @media (min-width: 1024px) {
    width: 376px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 304px;
  }

  @media (max-width: 767px) {
    width: 240px;
  }
}

.projects-index__mobile-more {
  @media (min-width: 480px) {
    display: none;
  }

  @media (max-width: 479px) {
    margin: 40px 0 0;
    min-width: 104px;
    justify-self: center;
  }
}
