@import "menu-radial/menu-radial";

.preloader {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110%;
  background-image: linear-gradient(to top, $background-color 90%, transparent 100%);
  transition: transform 0.6s ease-in-out;

  &__paths {
    animation: changeColors 2s linear infinite;
  }

  &.hidden {
    pointer-events: none;
    transform: translateY(110%);

    .preloader__paths {
      animation: none;
    }
  }
}

.body-bg {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  background-image: url($base-img + 'bg-575.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 576px) {
    background-image: url($base-img + 'bg-1439.jpg');
  }

  @media (min-width: 1440px) {
    background-image: url($base-img + 'bg-1440.jpg');
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($background-color, 0.2);
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

#webgl {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

@keyframes changeColors {
  25% {
    fill: var(--color-purple);
  }

  50% {
    fill: var(--color-yellow);
  }

  75% {
    fill: var(--color-pink);
  }

  100% {
    fill: var(--color-green);
  }
}
