.pattern {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  fill: var(--accent-color);

  &--inside {
    top: 8px;
    left: 8px;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
  }
}

.bg-pattern-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .pattern {
    @include clipPathAngles(12px);
  }
}
