// OFFER INDEX
.offer-index__form {
  @media (min-width: 768px) {
    position: relative;
    grid-template-columns: minmax(200px, 365px) minmax(400px, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: 60% 40%;
  }
}

.offer-index__button {
  @media (max-width: 767px) {
    justify-self: start;
    margin: 24px 0 0;
  }
}

.offer-index__button-phone {
  @media (min-width: 768px) {
    display: flex;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.offer-index__button-form {
  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: flex;
  }
}

.offer-index__wrapper-name {
  margin-right: -2px;
}
