// PROJECT
.project {
  @media (min-width: 1024px) {
    padding: 152px 0 80px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 245px 0 70px;
  }

  @media (max-width: 767px) {
    padding: 128px 0 45px;
  }
}

.project__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1439px) {
    margin: 0 auto;
  }

  @media (min-width: 1024px) {
    max-width: 960px;
  }
}

.project__title {
  @media (min-width: 1024px) {
    margin: 0 0 64px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
    margin: 0 auto 40px;

    .title__tag {
      display: none;
    }
  }

  @media (max-width: 767px) {
    max-width: 480px;
    margin: 0 auto 24px;
  }

  @media (max-width: 479px) {
    max-width: 360px;

    .title__text {
      line-height: 1.05;
    }
  }
}

.project__swiper {
  align-self: end;
  width: 100%;

  @media (min-width: 1024px) {
    max-width: 880px;
    margin: 0 0 64px;
  }

  @media (min-width: 941px) {
    overflow: hidden;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
    margin: 0 auto 40px;
  }

  @media (max-width: 767px) {
    max-width: calc(100% - 24px);
    margin: 0 auto 24px;
    padding: 0 24px 0 24px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.project__short-description {
  display: grid;
  grid-row-gap: 8px;
  align-self: end;

  p {
    margin: 0;
    color: #fff;
    line-height: 160%;
  }

  @media (min-width: 1024px) {
    max-width: 880px;
    margin: 0 0 64px;
    padding: 0 80px 0 0;

    p {
      font-size: 18px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
    margin: 0 auto 40px;

    p {
      font-size: 16px;
    }
  }

  @media (max-width: 767px) {
    margin: 0 auto 24px;

    p {
      font-size: 12px;
    }
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.project__works {
  grid-template-rows: max-content max-content;
  width: 100%;

  @media (min-width: 1024px) {
    margin: 0 0 80px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 740px;
    margin: 0 0 60px;
  }

  @media (max-width: 767px) {
    max-width: 580px;
    margin: 0 0 40px;
  }

  @media (min-width: 480px) and (max-width: 1023px) {
    width: calc(100vw - 48px);
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 479px) {
    width: 100vw;
    margin-right: -16px;
    margin-left: -16px;

    .slider__dots {
      padding: 19px 0 0;
    }
  }

  @media (max-width: 319px) {
    min-width: 100%;
  }
}

.project__works-container {
  align-items: start;
  @media (min-width: 1024px) {
    width: 100%;
    max-width: 800px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: calc(100vw - 128px);
    max-width: 640px;
  }

  @media (max-width: 767px) {
    width: calc(100vw - 80px);
    max-width: 480px;
  }

  @media (max-width: 479px) {
    width: calc(100vw - 56px);
  }

  @media (max-width: 319px) {
    min-width: 100%;
  }
}

.project__slider {
  align-items: start;
}

.project__slide {
  display: flex;
  align-items: start;
  padding: 8px;
  border: 1px solid;
  border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.4) 49.92%, rgba(255, 255, 255, 0.12) 100%);
  border-image-slice: 1;
}

.project__works-buttons {
  @media (max-width: 479px) {
    display: none;
  }
}

// INFO PROJECT
.info-project {
  width: 100%;

  @media (max-width: 1439px) {
    margin: 0 auto;
  }

  @media (min-width: 1024px) {
    padding: 80px 0 0;

    .item-step:not(:last-child) .item-step__icon::before {
      right: -170px;
      width: 170px;
      background-image: url("../img/icons/step-line-big.svg");
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
    padding: 64px 0 0;
  }

  @media (max-width: 767px) {
    max-width: 480px;
    padding: 40px 0 0;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.info-project__swiper {
  @media (min-width: 1024px) {
    margin: 0 0 24px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 0 0 64px;
  }

  @media (max-width: 767px) {
    margin: 0 0 24px;
  }
}

.info-project__slider-container {
  max-width: 960px;
}

.info-project__steps {
  margin: 0 0 64px;
}

.info-project__numbers {
  counter-reset: li;
}

.info-project__number {
  counter-increment: li;

  &::before {
    content: counter(li);
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    align-items: center;
    justify-content: center;
    color: var(--accent-color);
    font-weight: 300;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
  }

  @media (min-width: 1024px) {
    width: 210px;
  }

  @media (max-width: 767px) {
    &::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 200px;
  }

  @media (min-width: 768px) {
    &::before {
      width: 80px;
      height: 80px;
    }
  }

  @media (max-width: 767px) {
    width: 168px;

    &::before {
      width: 72px;
      height: 72px;
    }
  }
}

.info-project__list {
  display: grid;
  max-width: 880px;
  padding: 0;
  list-style: none;

  @media (max-width: 1439px) {
    margin: 0 auto;
  }

  @media (min-width: 1024px) {
    grid-row-gap: 40px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-row-gap: 32px;
  }

  @media (max-width: 767px) {
    grid-row-gap: 20px;
  }
}

.info-project__item .tag-small__content p {
  @media (min-width: 1024px) {
    line-height: 162%;
  }
}
