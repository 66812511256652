// SLIDER
.slider {
  position: relative;
  display: grid;
}

.slider__mask {
  position: relative;
  align-items: start;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  grid-row: 1/2;
  grid-column: 1/2;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media (min-width: 1024px) {
    mask-image: linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(196, 196, 196, 0.2) 5.8%, #c4c4c4 8.58%, #c4c4c4 91.44%, rgba(196, 196, 196, 0.2) 94.2%, rgba(196, 196, 196, 0) 100%);
  }

  @media (min-width: 480px) and (max-width: 1023px) {
    mask-image: linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(196, 196, 196, 0.2) 5.8%, #c4c4c4 8.58%, #c4c4c4 91.58%, rgba(196, 196, 196, 0.2) 96.94%, rgba(196, 196, 196, 0) 100%);
    width: calc(100% + 48px);
    margin: 0 -24px;
    padding: 0 24px;
  }

  @media (max-width: 479px) {
    width: 100vw;
    max-width: 480px;
    margin: 0 -16px;
    padding: 0 16px;
    mask-image: linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, #c4c4c4 5.24%, #c4c4c4 94.7%, rgba(196, 196, 196, 0) 100%);
  }
}

.project__works .slider__mask {
  @media (max-width: 479px) {
    margin: 0;
    padding: 0;
  }
}

.slider__container {
  display: grid;
  justify-self: center;
  pointer-events: visible;
}

.slider__wrapper {
  display: flex;
  padding: 0;
  list-style: none;
}

.slider__slide {
  flex-shrink: 0;
  margin: 0;
}

.slider__buttons {
  @media (min-width: 768px) {
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(2, 40px);
    grid-row: 1/2;
    grid-column: 1/2;
    grid-column-gap: 20px;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.slider__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: #fff;
  pointer-events: visible;
  transition: background-color 0.2s, box-shadow 0.2s;

  svg {
    position: relative;
    color: $background-color;
    transition: color 0.2s;
  }

  &--prev {
    svg {
      left: -2px;
    }
  }

  &--next {
    svg {
      left: 2px;
    }
  }

  &:hover,
  &:focus {
    background-color: var(--accent-color);
    box-shadow: 0 0 24px var(--accent-color);

    svg {
      color: #fff;
    }
  }
}

.slider__dots {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;

  .swiper-pagination-bullet {
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    border: none !important;
    background-color: var(--accent-color);
  }

  @media (min-width: 768px) {
    grid-column-gap: 32px;
    padding: 40px 0 0;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border: 2px solid #fff;
    }
  }

  @media (max-width: 767px) {
    grid-column-gap: 16px;
    padding: 24px 0 0;

    .swiper-pagination-bullet {
      width: 4px;
      height: 4px;
      border: 1px solid #fff;
    }
  }
}
