// TAG
.tag {
  border: 1px solid var(--accent-color);
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 768px) {
    padding: 7px 11px;
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 767px) {
    padding: 5px;
    font-size: 10px;
    line-height: 12px;
  }
}
