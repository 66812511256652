// OFFER
.offer {
  @media (min-width: 1024px) {
    padding: 80px 0;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }
}

.offer__container {
  display: grid;
}

.offer__wrapper {
  position: relative;
  display: grid;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 34.9%, rgba(255, 255, 255, 0) 100%);
  }

  @media (max-width: 1439px) {
    justify-content: center;
    justify-self: center;
    width: 100%;
  }

  @media (min-width: 1024px) {
    max-width: 960px;
    padding: 80px 0 0;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
    padding: 40px 0 0;
  }

  @media (max-width: 767px) {
    padding: 24px 0 0;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.offer__sub-title {
  width: 100%;
  max-width: 880px;
  color: #fff;
  font-weight: 700;

  @media (min-width: 1440px) {
    justify-self: end;
  }

  @media (min-width: 1024px) {
    margin: 0 0 40px;
    font-size: 40px;
    line-height: 52px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 0 0 32px;
    font-size: 24px;
    line-height: 31px;
  }

  @media (max-width: 767px) {
    margin: 0 0 16px;
    font-size: 18px;
    line-height: 23px;
  }
}

.offer-project .offer__sub-title {
  @media (min-width: 1024px) {
    margin: 0 0 10px;
  }
}

.offer__form {
  @media (min-width: 1440px) {
    justify-self: end;
  }
}
