// OFFER INDEX
.offer-project__form {
  @media (min-width: 768px) {
    position: relative;
    grid-template-columns: minmax(200px, 434px) minmax(400px, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: 60% 40%;
  }
}

.offer-project__button {
  @media (max-width: 767px) {
    justify-self: start;
    margin: 24px 0 0;
  }
}

.offer-project__button-phone {
  @media (min-width: 768px) {
    display: flex;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.offer-project__button-form {
  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: flex;
  }
}

.offer-project__wrapper-name {
  margin-right: -2px;
}
