// DEVELOP
.develop {

  @media (min-width: 1024px) {
    padding: 80px 0;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 20px 0 40px;
  }
}

.develop__container {
  display: grid;
}

.develop__wrapper {
  display: grid;
  width: 100%;

  @media (max-width: 1439px) {
    justify-self: center;
  }

  @media (min-width: 1024px) {
    max-width: 960px;
    padding: 0 0 0 80px
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.develop__sub-title {
  max-width: 650px;
  margin: 0 0 64px;

  @media (min-width: 1024px) {
    margin: 0 0 64px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 0 0 44px;
    max-width: 520px;
  }

  @media (max-width: 767px) {
    margin: 0 0 24px;
  }
}

.develop__list {
  display: grid;
  justify-content: space-between;
  margin: 0 0 40px;
  padding: 0;
  list-style: none;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 240px);
    grid-gap: 40px 20px;
    max-width: 880px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: repeat(3, 200px);
    grid-gap: 24px 20px;
    margin: 0 0 24px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    grid-template-columns: repeat(3, 136px);
    grid-gap: 16px 10px;
    margin: 0 0 24px;
  }

  @media (max-width: 479px) {
    grid-template-columns: repeat(2, 136px);
    grid-gap: 16px;
    justify-content: center;
    margin: 0 0 24px;
  }
}

.develop__postscript {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80px;
  padding: 24px;
  color: #fff;
  font-weight: 700;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  p {
    position: relative;
  }

  .pattern {
    fill: #fff;
  }
}

// ITEM DEVELOP
.item-develop {
  display: grid;

  @media (min-width: 1024px) {
    grid-template-rows: 240px 1fr;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-template-rows: 200px 1fr;
  }

  @media (max-width: 767px) {
    grid-template-rows: 136px 1fr;
  }
}

.item-develop__title {
  order: 2;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin: 16px 0 0;

  @media (min-width: 1024px) {
    min-height: 64px;
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    min-height: 48px;
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: 767px) {
    min-height: 32px;
    font-size: 12px;
    line-height: 16px;
    margin: 8px 0 0;
  }
}

.item-develop__image-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 1;
  padding: 24px;
  border: 1px solid;
  border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.4) 49.92%, rgba(255, 255, 255, 0.12) 100%);
  border-image-slice: 1;

  transition: box-shadow 0.2s;

  &:hover,
  &:focus {
    border: 1px solid var(--accent-color);
    box-shadow: 0 0 16px var(--accent-color);
  }
}

.item-develop__pattern {
  top: 8px;
  left: 8px;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
}

.item-develop__image {
  position: relative;
}
