// OFFER TEAM
.offer-team__form {
  @media (min-width: 1024px) {
    grid-row-gap: 11px;
    grid-template-columns: minmax(240px, 320px) minmax(240px, 250px) minmax(240px, 310px);
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-row-gap: 32px;
    grid-template-columns: minmax(100px, 240px) minmax(100px, 160px) minmax(100px, 240px);
  }

  @media (max-width: 767px) {
    grid-template-columns: 58% 42%;
    grid-row-gap: 8px;
  }
}

.offer-team__wrapper {
  &--file {
    grid-column: 1/-1;
    justify-content: space-between;
  }

  &--name {
    margin-right: -2px;
  }

  &--phone {
    margin-right: -2px;
  }

  @media (max-width: 767px) {
    &--email {
      grid-column: 1/-1;
    }

    &--file {
      flex-direction: column;
      padding: 0;
      border-bottom: none;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
