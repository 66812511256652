.sphere-preloader {

  // @media (min-width: 1440px) {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url($base-img + 'sphere-green.png');
  //   background-position: center;
  //   transform: translate(-50%, -50%);
  //   animation: preloaderSphere 1s linear infinite alternate;
  // }
}

.menu-radial {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: none;
  width: 766px;
  height: 766px;
  margin: auto 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  pointer-events: none;
  user-select: none;
  transform: translate(48.5%, 0);

  @media (min-width: 1440px) {
    display: block;
  }

  @media (max-width: 1800px) {
    //transform: translate(68%, 0);
    transform: translate(calc(48% + calc((1800px - 100vw) / (1800 - 1440) * (766 * 0.22))), 0);
  }

  ul {
    list-style: none;
  }

  a {
    pointer-events: visible;

    @media (hover: hover) {
      will-change: filter;
      transition: var(--transition-main);
      transition-property: color, filter;

      &:hover {
        color: var(--accent-color);
        filter: drop-shadow(0 0 12px var(--accent-color));
      }
    }
  }

  &__circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__inner {
    position: absolute;
    top: 50%;
    right: calc(100% - 300px);
    z-index: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 600px;
    //height: unquote('min(70%, 60vh)');//размытие текста при translateY из-за нецелых чисел
    height: 540px;
    pointer-events: none;
    transform: translateY(-50%);
    -webkit-mask-image: linear-gradient(0deg, transparent, rgba(0, 0, 0, 1) 40px calc(100% - 40px), transparent);
    mask-image: linear-gradient(0deg, transparent, rgba(0, 0, 0, 1) 40px calc(100% - 40px), transparent);

    @media (max-height: 700px) {
      height: 360px;
    }

    @media (max-height: 550px) {
      height: 60vh;
    }
  }

  &__list {
    position: relative;
    padding: 0;
    color: #fff;

    li {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__sublist {
    display: none;
    max-width: 280px;
    margin-top: 16px;
    padding-left: 150px;
    text-align: left;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;

    @media (hover: hover) {
      &:hover {
        .icon {
          border-color: var(--accent-color);
          background: #fff;
        }
      }
    }

    .icon {
      position: relative;
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin-left: 16px;
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: #1b1f3a;
      transition: var(--transition-main);
      transition-property: border-color, background-color;

      &::after,
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: conic-gradient(from 0.33turn at 50% 50%, #fff, var(--accent-color));
        opacity: 0;
        transform: translate(-50%, -50%);
      }

      &::after {
        width: 52px;
        height: 52px;
        background: #fff;
      }

      svg {
        z-index: 1;
      }
    }

    .icon-pointer {
      display: none;
      align-self: flex-start;
      margin-right: -32px;
      margin-left: 8px;
      fill: #fff;
      transform: translateY(-10px);
      fill-rule: evenodd;
      clip-rule: evenodd;

      @media (max-width: 1700px) {
        width: 100px;
        transform: translateY(-16px);
      }
    }

    &.is-active {
      margin-top: 18px;

      + .menu-radial__sublist {
        display: block;
      }

      > span:first-child {
        align-self: flex-start;
        transform: translateY(-18px);
      }

      .icon-pointer {
        display: block;
      }

      .icon {
        width: 64px;
        height: 64px;
        margin-left: 0;
        background: #fff;
        color: var(--accent-color);
        box-shadow: 0 0 24px var(--accent-color);

        &::before {
          animation: loopIconBorder 8s linear infinite;
        }

        &::after,
        &::before {
          opacity: 1;
        }
      }
    }
  }

  &__sublink {
    position: relative;
    display: inline-flex;
    max-width: 128px;
    text-transform: capitalize;

    .dot {
      position: absolute;
      top: 50%;
      left: 100%;
      display: flex;
      align-items: center;
      transform: translateY(-50%);

      &::before {
        content: '';
        display: none;
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        margin-left: 8px;
        border: 1px solid #fff;
        border-radius: 50%;
      }

      &::after {
        content: '';
        display: inline-flex;
        flex: 1;
        width: 0;
        height: 1px;
        background-image: linear-gradient(to right, transparent, var(--accent-color));
      }

      i {
        display: inline-flex;
        flex-shrink: 0;
        order: 1;
        width: 16px;
        height: 16px;
        border: 1px solid var(--accent-color);
        border-radius: 50%;
        background-color: #1b1f3a;
        font-size: 0;
        line-height: 1;
      }
    }

    &.is-active {
      .dot {
        &::before {
          display: inline-flex;
        }

        &::after {
          background-color: #fff;
          background-image: none;
        }

        i {
          border-width: 4px;
          border-color: #fff;
          background-color: var(--accent-color);
        }
      }
    }
  }
}

#drag-area {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: grab;
  pointer-events: visible;

  img {
    display: none;
    opacity: 0.7;
    pointer-events: none;
    transform: translateX(200px);

    @media (max-width: 1800px) {
      transform: translateX(calc(200px + calc((1800px - 100vw) / (1800 - 1440) * -110)));
    }
  }

  &.info-visible {
    img {
      display: block;
    }
  }
}

@keyframes loopIconBorder {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes preloaderSphere {
  to {
    opacity: 0.6;
  }
}
