@mixin transition($element: all, $time: 0.25s, $function: linear) {
  transition: $time $function;
  transition-property: #{$element};
}

@mixin transition-cubic($time: 0.25s, $function: cubic-bezier(1,0,0,1), $element: all) {
  transition: $element $time $function;
}

//example heightVH:
//@include heightVH(100, 'min');
//@include heightVH(80, 'max');
//@include heightVH(100);
@mixin heightVH($val: 100, $pre: '') {
  $vh: calc(var(--vh, 1vh) * #{$val});

  @if $pre != '' {
    #{$pre}-height: $val+vh;
    #{$pre}-height: $vh;
  }

  @else {
    height: $val+vh;
    height: $vh;
  }
}

@mixin scrollbar($width: 2px, $rail-color: #ccc, $bar-color: #999) {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-color: $bar-color $rail-color;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: $width;
    height: $width;

    background-color: $rail-color;
  }

  &::-webkit-scrollbar-track {
    background-color: $rail-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bar-color;
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin clipPathAngles($clip-width: 12px) {
  clip-path: polygon($clip-width 0, 100% 0, 100% calc(100% - #{$clip-width}), calc(100% - #{$clip-width}) 100%, 0 100%, 0 $clip-width);
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
// @include placeholder {
//     color: green;
// }
