// STACk
.stack {
  @media (min-width: 1024px) {
    padding: 80px 0;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }
}

.stack__container {
  display: grid;
}

.stack__wrapper {
  display: grid;
  width: 100%;

  @media (max-width: 1439px) {
    justify-self: center;
  }

  @media (min-width: 1024px) {
    max-width: 960px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.stack__title {
  @media (min-width: 1024px) {
    margin: 0 0 64px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 0 0 40px;
  }

  @media (max-width: 767px) {
    margin: 0 0 24px;
  }
}

.stack__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-self: center;
  max-width: 980px;
  padding: 0;
  list-style: none;

  @media (min-width: 1024px) {
    width: calc(100% + 20px);
    margin: -10px 0;
    padding: 0 0 0 80px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: calc(100% + 16px);
    margin: -8px 0;
  }

  @media (max-width: 767px) {
    width: calc(100% + 12px);
    margin: -6px 0;
  }
}
