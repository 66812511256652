// MODAL
.modal {
  overflow: hidden;
  &[aria-hidden="true"] {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: grid;
    overflow-y: auto;
    width: 100%;
    min-width: 320px;
    height: 100%;
    max-height: 100%;
  }

  &[aria-hidden="false"] {
    display: none;
  }
}

.modal__wrapper {
  display: grid;
  align-items: center;
  justify-items: center;
  background: rgba(35, 22, 71, 0.85);
  backdrop-filter: blur(40px);

  @media (min-width: 768px) {
    padding: 5px;
  }

  @media (max-width: 767px) {
    padding: 8px;
    max-height: 100%;
  }
}

.modal__container {
  display: grid;
  width: 100%;
  max-width: 575px;
  opacity: 0;
  padding-right: 56px;
  transition: opacity 0.2s, transform 0.2s;
  transform: translateY(-100px);

  @media (max-width: 767px) {
    padding: 0;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
  }
}

.modal__close {
  right: -55px;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
  justify-self: flex-end;
  width: 40px;
  height: 40px;
  margin: 0 0 15px;
  padding: 0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: transparent;
  color: #fff;
  fill: #fff;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #fff;
    pointer-events: none;
  }

  &::before {
    transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(45deg);
  }

  // @media (max-width: 1279px) {
  //   right: 0;
  // }

  @media (max-width: 1023px) {
    right: -54px;
  }

  @media (max-width: 767px) {
    margin: 0;
    top: 16px;
    right: 16px;
    position: absolute;
    width: 32px;
    height: 32px;
  }
}

.telegram {
  position: relative;
  max-width: 1086px;

  @media (min-width: 1024px) {
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -28px;
      bottom: 32px;
      width: 1px;
      z-index: 2;
      height: 582px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgb(255, 255, 255) 34.9%, rgba(255, 255, 255, 0));
    }
  }


  @media (max-width: 1023px) {
    max-width: 575px;
  }

  @media (max-width: 767px) {
    max-width: 100%;
  }

  .form-modal {
    grid-template-columns: 1fr 1fr;
    max-width: 100%;

    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
  }

  .telegram__wrapper {
    @media (min-width: 620px) {
      display: grid;
      grid-template-columns: 178px 1fr;
      grid-column-gap: 40px;
    }

    @media (max-width: 1023px) {
      margin-top: 40px;
      grid-template-columns: 160px 1fr;
      grid-column-gap: 32px;
    }
  }

  .form-modal__fieldset {
    padding-right: 32px;
    max-width: 400px;

    @media (max-width: 1023px) {
      padding-right: 0;
      max-width: 368px;
    }
  }
}

.telegram__wrapper {
  display: none;
}

.telegram__title {
  position: relative;
  justify-self: center;
  padding-top: 80px;
  font-weight: 700;
  grid-column: 1/-1;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  color: #FFFFFF;
  max-width: 300px;
  margin: 0 0 40px;

  &::before {
    content: "";
    position: absolute;
    width: 64px;
    height: 64px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("../img/telegram.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (max-width: 1023px) {
    max-width: 100%;
    padding-top: 40px;
    padding-left: 56px;
    text-align: left;
    font-size: 24px;
    line-height: 31px;
    margin: 0 0 16px;

    &::before {
      width: 40px;
      height: 40px;
      top: auto;
      bottom: -4px;
      left: 0;
      transform: translate(0, 0);
    }
  }
}
.telegram__image-wrapper {
  position: relative;
  display: flex;
  width: 176px;
  height: 176px;
  grid-column: 1/2;
  margin: 0 0 70px;
  padding: 8px;

  &::before {
    content: "";
    top: 16px;
    left: 16px;
    z-index: -1;
    position: absolute;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    border: 1px solid;
    border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.08) 0%, #fff 49.92%, rgba(255, 255, 255, 0.08) 100%);
    border-image-slice: 1;
    transition: box-shadow 0.2s;
  }

  &:hover,
  &:focus-within {
    &::before {
      border: 1px solid var(--accent-color);
      box-shadow: 0 0 16px var(--accent-color);
    }
  }

  @media (max-width: 1023px) {
    width: 110px;
    height: 110px;
    padding: 4px;
    margin: 0 0 40px;
    justify-self: end;

    &::before {
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      top: 8px;
      left: 8px;
    }
  }
}
.telegram__image {
  position: absolute;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  object-fit: cover;

  @media (max-width: 1023px) {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
  }
}
.telegram__info {
  align-content: center;
  margin: 0 0 70px;
  display: grid;
  grid-column: 2/3;

  @media (max-width: 1023px) {
    margin: 0 0 40px;
    max-width: 170px;
  }
}
.telegram__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 160%;
  color: #FFFFFF;

  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 160%;
  }
}
.telegram__position {
  margin: 0 0 8px;
  font-size: 14px;
  line-height: 160%;
  color: var(--accent-color);

  @media (max-width: 1023px) {
    font-size: 12px;
    margin: 0;
  }
}
.telegram__site {
  font-size: 18px;
  line-height: 160%;
  margin: 0 0 8px;

  @media (max-width: 1023px) {
    font-size: 16px;
    margin: 0;
  }
}
.telegram__phone {
  font-size: 18px;
  line-height: 160%;

  @media (max-width: 1023px) {
    font-size: 16px;
  }
}

.telegram__link {
  grid-column: 1/-1;
  align-self: end;
  justify-self: center;
  min-width: 248px;
}

.telegram__decor {
  top: 1px;
  left: 0;
  z-index: -1;

  @media (max-width: 1024px) {
    top: -2px;
    left: -2px;
  }

  @media (max-width: 767px) {
    top: -4px;
    left: 0px;
  }
}
.telegram__small-link {
  display: none;
}

.telegram .telegram__small-link {
  @media (min-width: 620px) {
    display: none;
  }

  @media (max-width: 619px) {
    display: flex;
    position: relative;
    margin-top: 24px;
    padding-left: 24px;
    padding-bottom: 10px;

    &::after {
      top: 2px;
      left: 0;
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      background-image: url("../img/telegram.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

// FORM MODAL
.form-modal {
  position: relative;
  display: grid;
  justify-items: center;
  max-width: 576px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4);

  > .pattern {
    top: 16px;
    left: 16px;
    pointer-events: none;
  }

  @media (min-width: 768px) {
    padding: 72px 32px 40px;

    &::before {
      content: "";
      position: absolute;
      top: -10px;
      right: -21px;
      width: 23px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.4);
      transform: rotate(-45deg);
    }
  }

  // @media (min-width: 1024px) and (max-width: 1279px) {
  //   &::before {
  //     right: -12px;
  //     transform: rotate(90deg);
  //   }
  // }

  @media (max-width: 767px) {
    max-width: 100%;
    min-height: 100%;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      top: 7px;
      right: -5px;
      width: 24px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.4);
      transform: rotate(-45deg);
    }

    > .pattern {
      top: 8px;
      left: 8px;
      width: calc(100% - 16px);
      min-height: 100%;
      height: calc(100% - 16px);
    }
  }

  @media (max-width: 767px) {
    padding: 38px 32px;
  }
}

.form-modal__wrapper {
  margin: 0 0 14px;
}

.form-modal__decor {
  position: absolute;
  top: -9px;
  left: -9px;
  color: var(--accent-color);

  @media (max-width: 767px) {
    display: none;

  }
}

.form-modal__fieldset {
  position: relative;
  display: grid;
  margin: 0;
  padding: 0;
  border: none;

  @media (min-width: 768px) {
    max-width: 368px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 320px;
  }

  @media (max-width: 479px) {
    max-width: 368px;
  }
}

.form-modal__legend {
  margin: 0 0 8px;
  color: #fff;
  font-weight: 700;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 52px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    font-size: 32px;
    line-height: 44px;
  }

  @media (max-width: 479px) {
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    margin: 0 0 8px;
  }
}

.form-modal__text {
  color: #fff;
  line-height: 160%;
  text-align: center;

  @media (min-width: 768px) {
    width: calc(100% + 60px);
    margin: 0 -30px 24px;
    font-size: 18px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    width: calc(100% + 40px);
    margin: 0 -20px 16px;
    font-size: 16px;
  }

  @media (max-width: 479px) {
    width: calc(100% + 20px);
    margin: 0 0 16px;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
  }
}

.form-modal__checkbox {
  padding: 14px 0 0;

  @media (min-width: 768px) {
    width: calc(100% + 40px);
    margin: 0 -40px 0 0;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    width: calc(100% + 20px);
    margin: 0 -20px 0 0;
  }

  @media (max-width: 767px) {
    padding: 14px 0 0;
  }
}

.form-modal__button {
  justify-self: center;
  margin: 0;
}

// FORM
.form__checkbox {
  display: grid;
  grid-row-gap: 8px;
  margin: 0 0 40px;

  @media (max-width: 479px) {
    margin: 0 0 24px;
    grid-row-gap: 24px;
  }
}

.form__checkbox-wrapper {
  display: grid;
}

.form__label-checkbox {
  position: relative;
  color: #fff;
  line-height: 160%;
  cursor: pointer;
  padding: 0 0 0 32px;
  transition: box-shadow 0.2s, border 0.2s;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    border: 1px solid #fff;
    background-color: transparent;
  }

  a {
    color: var(--accent-color);
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    font-size: 14px;

  }

  @media (min-width: 480px) and (max-width: 767px) {
    font-size: 12px;
  }

  @media (max-width: 479px) {
    font-size: 10px;
    margin-right: -5px;
  }
}

.form__checkbox-wrapper._error .form__label-checkbox::before {
  border: 1px solid var(--error-color);
  box-shadow: 0 0 15px var(--error-color);
}

.form__checkbox input:checked ~ .form__label-checkbox {
  &::before {
    background-color: #fff;
    border: 5px solid var(--accent-color);
  }

  // @media (min-width: 768px) {
  //   &::before {
  //   }
  // }

  // @media (min-width: 480px) and (max-width: 767px) {
  //   &::before {
  //     border: 3.5px solid var(--accent-color);
  //   }
  // }

  // @media (max-width: 479px) {
  //   &::before {
  //     border: 2.5px solid var(--accent-color);
  //   }
  // }
}

.modal__success {
  display: none;

  &--opened {
    position: relative;
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100%;
    max-width: 392px;
    min-height: 206px;
    padding: 32px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
  }
}

.modal__success-decor {
  position: absolute;
  top: -9px;
  left: -9px;
  color: var(--accent-color);
}

.modal__success-text {
  max-width: 200px;
  margin: 0;
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.modal__success .pattern {
  top: 16px;
  left: 16px;
}

.modal__success-ball {
  position: absolute;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
  }

  &--01 {
    &::before {
      top: 94px;
      left: -50px;
      width: 80px;
      height: 56px;
      background-image: url("../img/modal/ball01.png");
    }
  }

  &--02 {
    &::before {
      top: 220px;
      left: 110px;
      width: 44px;
      height: 31px;
    }
  }

  &--03 {
    &::before {
      top: -38px;
      left: 226px;
      width: 32px;
      height: 32px;
      background-image: url("../img/modal/ball03.png");
    }
  }

  &--04 {
    &::before {
      bottom: 12px;
      right: 48px;
      width: 54px;
      height: 38px;
      background-image: url("../img/modal/ball04.png");
    }
  }

  &--05 {
    &::before {
      top: 10px;
      right: -40px;
      width: 96px;
      height: 67px;
    }
  }

  @media (max-width: 479px) {
    &--01 {
      &::before {
        left: -10px;
      }
    }

    &--05 {
      &::before {
        right: -10px;
      }
    }
  }
}

html[data-theme="green"] .modal__success-ball {
  &--02 {
    &::before {
      background-image: url("../img/modal/ball02.png");
    }
  }

  &--05 {
    &::before {
      background-image: url("../img/modal/ball05.png");
    }
  }
}

html[data-theme="purple"] .modal__success-ball {
  &--02 {
    &::before {
      background-image: url("../img/modal/ball02-fiol.png");
    }
  }

  &--05 {
    &::before {
      background-image: url("../img/modal/ball05-fiol.png");
    }
  }
}

html[data-theme="yellow"] .modal__success-ball {
  &--02 {
    &::before {
      background-image: url("../img/modal/ball02-yellow.png");
    }
  }

  &--05 {
    &::before {
      background-image: url("../img/modal/ball05-yellow.png");
    }
  }
}

html[data-theme="pink"] .modal__success-ball {
  &--02 {
    &::before {
      background-image: url("../img/modal/ball02-purp.png");
    }
  }

  &--05 {
    &::before {
      background-image: url("../img/modal/ball05-purp.png");
    }
  }
}

.tg-modal {
  max-width: 567px;

  .telegram__title {
    @media (max-width: 1023px) {
      display: block;
      position: relative;
      justify-self: center;
      padding-top: 80px;
      // left: 50%;
      // transform: translateX(-50%);
      padding-left: 0;
      font-weight: 700;
      grid-column: 1/-1;
      font-size: 40px;
      line-height: 52px;
      text-align: center;
      color: #FFFFFF;
      max-width: 300px;
      margin: 0 auto 40px;

      &::before {
        content: "";
        position: absolute;
        width: 64px;
        height: 64px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-image: url("../img/telegram.png");
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    @media (max-width: 767px) {
      padding-top: 56px;
      font-size: 18px;
      line-height: 23px;

      &::before {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.tg-modal {
  .form-modal {
    display: none;
  }
}

.tg-modal__container {
  display: none;
}

.tg-modal {
  .tg-modal__container {
    position: relative;
    display: grid;
    justify-items: center;
    max-width: 512px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.4);

    > .pattern {
      top: 16px;
      left: 16px;
      pointer-events: none;
    }

    @media (min-width: 768px) {
      padding: 56px 32px 40px;

      &::before {
        content: "";
        position: absolute;
        top: -10px;
        right: -21px;
        width: 23px;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.4);
        transform: rotate(-45deg);
      }
    }

    @media (max-width: 767px) {
      max-width: 100%;
      min-height: 100%;
      align-items: center;

      &::before {
        content: "";
        position: absolute;
        top: 7px;
        right: -5px;
        width: 24px;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.4);
        transform: rotate(-45deg);
      }

      > .pattern {
        top: 8px;
        left: 8px;
        width: calc(100% - 16px);
        min-height: 100%;
        height: calc(100% - 16px);
      }
    }

    @media (max-width: 767px) {
      padding: 84px 24px 20px;
    }
  }

  .telegram__wrapper {
      display: grid;
      grid-template-columns: 178px 1fr;
      grid-column-gap: 40px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-column-gap: 0px;
    }
  }

  .telegram__image-wrapper {
    margin: 0 0 48px;

    @media (max-width: 1023px) {
      position: relative;
      display: flex;
      width: 176px;
      height: 176px;
      grid-column: 1/2;
      margin: 0 0 48px;
      padding: 8px;

      &::before {
        content: "";
        top: 16px;
        left: 16px;
        z-index: -1;
        position: absolute;
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        border: 1px solid;
        border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.08) 0%, #fff 49.92%, rgba(255, 255, 255, 0.08) 100%);
        border-image-slice: 1;
        transition: box-shadow 0.2s;
      }
    }

    @media (max-width: 767px) {
      width: 132px;
      height: 132px;
      padding: 4px;
      margin: 0 0 8px;
      justify-self: end;
      grid-column: 1/-1;
      justify-self: center;

      &::before {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        top: 8px;
        left: 8px;
      }
    }
  }

  .telegram__image {
    @media (max-width: 1023px) {
      position: absolute;
      width: calc(100% - 16px);
      height: calc(100% - 16px);
      object-fit: cover;
    }

    @media (max-width: 767px) {
      width: calc(100% - 12px);
      height: calc(100% - 12px);
    }
  }

  .telegram__info {
    margin: 0 0 48px;
    @media (max-width: 1023px) {
      align-content: center;
      margin: 0 0 48px;
      display: grid;
      grid-column: 2/3;
    }

    @media (max-width: 767px) {
      margin: 0 0 40px;
      max-width: 170px;
      grid-column: 1/-1;
      justify-self: center;
      justify-items: center;
    }
  }
  .telegram__name {
    @media (max-width: 1023px) {
      font-weight: 700;
      font-size: 18px;
      line-height: 160%;
      color: #FFFFFF;
    }

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 160%;
    }
  }
  .telegram__position {
    @media (max-width: 1023px) {
      margin: 0 0 8px;
      font-size: 14px;
      line-height: 160%;
      color: var(--accent-color);
    }

    @media (max-width: 767px) {
      font-size: 12px;
      margin: 0 0 8px;
    }
  }
  .telegram__site {
    @media (max-width: 1023px) {
      font-size: 18px;
      line-height: 160%;
      margin: 0 0 8px;
    }

    @media (max-width: 767px) {
      font-size: 16px;
      margin: 0;
    }
  }
  .telegram__phone {
    @media (max-width: 1023px) {
      font-size: 18px;
      line-height: 160%;
    }

    @media (max-width: 767px) {
      font-size: 16px;
    }
    }

  .telegram__decor {
    @media (max-width: 1023px) {
      top: 2px;
      left: 2px;
      z-index: -1;
    }

    @media (max-width: 767px) {
      top: -4px;
      left: 0px;
    }
  }

  .telegram__link {
    @media (max-width: 767px) {
      min-width: 168px;

    }
  }
}
