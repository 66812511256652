// CONTACTS
.contacts {
  @media (min-width: 1024px) {
    padding: 152px 0 80px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 245px 0 40px;
  }

  @media (max-width: 767px) {
    padding: 124px 0 0;
  }
}

.contacts__container {
  display: grid;
}

.contacts__wrapper {
  display: grid;
  width: 100%;

  @media (max-width: 1439px) {
    justify-self: center;
  }

  @media (min-width: 1024px) {
    max-width: 960px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.contacts__title {
  @media (min-width: 1024px) {
    margin: 0 0 80px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 0 0 40px;
  }

  @media (max-width: 767px) {
    margin: 0 0 24px;
    padding-bottom: 21px;
  }
}

.contacts__list {
  display: grid;
  padding: 0;
  list-style: none;

  @media (min-width: 1024px) {
    grid-template-columns: 1.35fr 1fr;
    grid-gap: 64px 20px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: 1.58fr 1fr;
    grid-gap: 40px 20px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-row-gap: 23px;
  }
}

// ITEM CONTACT
.item-contact {
  display: grid;
}

.item-contact__title {
  justify-self: start;
  color: var(--accent-color);
  font-weight: 400;
  line-height: 160%;

  @media (min-width: 1024px) {
    margin: 0 0 12px;
    font-size: 16px;
  }

  @media (max-width: 1023px) {
    margin: 0 0 8px;
    font-size: 12px;
  }

  @media (max-width: 767px) {
    font-size: 10px;
  }
}

.item-contact__value {
  justify-self: start;
  color: #fff;
  font-weight: 700;
  line-height: 160%;

  @media (min-width: 1024px) {
    font-size: 18px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
}
