// TEAM
.team {
  @media (min-width: 1024px) {
    padding: 152px 0 80px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 245px 0 59px;
  }

  @media (max-width: 767px) {
    padding: 124px 0 39px;
  }
}

.team__container {
  display: grid;
}

.team__wrapper {
  display: grid;
  width: 100%;

  @media (max-width: 1439px) {
    justify-self: center;
  }

  @media (min-width: 1024px) {
    max-width: 960px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;
  }

  @media (max-width: 479px) {
    max-width: 360px;
  }
}

.team__title {
  @media (min-width: 1024px) {
    margin: 0 0 70px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 0 0 46px;
  }

  @media (max-width: 767px) {
    margin: 0 0 27px;
    padding-bottom: 21px;
  }
}

.team__list {
  display: grid;
  justify-content: space-between;
  padding: 0;
  list-style: none;

  @media (max-width: 1439px) {
    justify-self: center;
    width: 100%;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 240px);
    grid-gap: 69px 20px;
    max-width: 880px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: repeat(3, 186px);
    grid-gap: 46px 20px;
  }

  @media (max-width: 767px) {
    text-align: center;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    grid-template-columns: repeat(3, 136px);
    grid-gap: 24px 10px;
  }

  @media (max-width: 479px) {
    grid-template-columns: repeat(2, 136px);
    grid-gap: 28px 16px;
    justify-content: center;
  }
}

// ITEM TEAM
.item-team {
  display: grid;

  &:hover,
  &:focus-within {
    .item-team__image-wrap {
      border: 1px solid var(--accent-color);
      box-shadow: 0 0 16px var(--accent-color);
    }
  }
}

.item-team__name {
  order: 2;
  margin: 0;
  color: #fff;
  font-weight: 700;
  line-height: 160%;

  @media (min-width: 1024px) {
    font-size: 18px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.item-team__position {
  order: 3;
  color: var(--accent-color);
  line-height: 160%;
  transition: 0.2s;

  @media (min-width: 1024px) {
    margin: -3px 0 22px;
    font-size: 14px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin: -3px 0 19px;
    font-size: 12px;
  }

  @media (max-width: 767px) {
    margin: 0 0 8px;
    font-size: 10px;
  }
}

.item-team__description {
  order: 4;
  color: #fff;
  line-height: 160%;

  @media (min-width: 1024px) {
    font-size: 14px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 12px;
  }

  @media (max-width: 767px) {
    font-size: 10px;
  }
}

.item-team__image-wrap {
  position: relative;
  display: flex;
  order: 1;
  border: 1px solid;
  border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0.08) 0%, #fff 49.92%, rgba(255, 255, 255, 0.08) 100%);
  border-image-slice: 1;
  transition: box-shadow 0.2s;

  @media (min-width: 1024px) {
    width: 208px;
    height: 208px;
    margin: 26px 0 16px 30px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 164px;
    height: 164px;
    margin: 16px 0 17px 22px;
  }

  @media (max-width: 767px) {
    width: 124px;
    height: 124px;
    margin: 8px 0 8px 12px;
  }
}

.item-team__image {
  position: absolute;
  z-index: 1;
  object-fit: cover;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);

  @media (min-width: 1024px) {
    top: -24px;
    left: -24px;
    width: calc(100% + 2px);
    max-width: calc(100% + 2px);
    height: calc(100% + 2px);
    max-height: calc(100% + 2px);
  }

  @media (max-width: 1023px) {
    width: calc(100% - 2px);
    max-width: calc(100% - 2px);
    height: calc(100% - 2px);
    max-height: calc(100% - 2px);
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    top: -16px;
    left: -16px;
  }

  @media (max-width: 767px) {
    top: -8px;
    left: -8px;
  }
}
