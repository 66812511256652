// PREVIEW
.preview {
  position: relative;
  display: grid;
  align-content: center;
  height: 100vh;

  @media (min-width: 1024px) {
    min-height: 680px;
  }

  @media (max-width: 1023px) {
    min-height: 480px;
  }
}

.preview__container {
  display: grid;
}

.preview__wrapper {
  position: relative;
  display: grid;
  width: 100%;

  @media (max-width: 1439px) {
    justify-self: center;
  }

  @media (min-width: 1024px) {
    max-width: 960px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;

    @media (min-height: 720px) {
      top: -110px;
    }
  }

  @media (min-width: 480px) and (max-width: 767px) {
    max-width: 480px;

    @media (min-height: 720px) {
      top: -110px;
    }
  }

  @media (max-width: 479px) {
    max-width: 360px;
    top: -20px;
  }
}

.preview__title {
  width: 100%;

  @media (min-width: 1024px) {
    margin: 0 0 64px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 0 0 40px;
  }

  @media (max-width: 767px) {
    margin: 0 0 24px;
    padding-bottom: 20px;
  }
}

.preview__form-description {
  max-width: 700px;
  margin: 0 0 14px;
  padding: 0 80px;
  color: #fff;
  line-height: 160%;

  @media (min-width: 1024px) {
    padding: 0 80px;
    font-size: 18px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 0 120px 0 40px;
    font-size: 16px;
    margin: 0 0 24px;
  }

  @media (max-width: 767px) {
    padding: 0 24px;
    font-size: 12px;
    margin: 0 0 24px;
  }
}

.preview__form {
  width: 100%;
  max-width: 720px;

  @media (min-width: 1024px) {
    padding: 0 80px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 0 40px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    padding: 0 24px;
  }
}

.preview__button {
  @media (max-width: 479px) {
    min-width: 104px;
  }
}

.preview__scroll {
  position: absolute;
  bottom: 40px;
  left: 50%;
  background-size: cover;
  transform: translateX(-50%);

  &--mouse {
    width: 24px;
    height: 63px;
    background-image: url("../img/icons/scroll-mouse.svg");
  }

  &--touch {
    background-image: url("../img/icons/scroll-touch.svg");
  }

  @media (max-width: 1023px) {
    bottom: 24px;
  }

  @media (min-width: 480px) {
    &--touch {
      width: 42px;
      height: 70px;
    }
  }

  @media (max-width: 479px) {
    bottom: 40px;
    &--touch {
      width: 32px;
      height: 48px;

    }
  }
}
